<div class="container-fluid">
    <div class="row mb-5 mx-3">
        <div class="col">
            <h5 class="font-24">How can we help you?</h5>
        </div>
    </div>
    
    <div class="row mx-3">
        <div class="col-xxl-7 col-xl-7 col-lg-7 col-auto">
            <div class="card">
                <div class="card-body px-4 py-4">
                    <small class="font-14">
                        Have any question? Please fill out the form below to connect with us,<br/>
                        and we will get back to you at the soonest possible time.
                    </small>

                    <form [formGroup]="form" class="mt-3">
                        <div class="row mb-3">
                            <div class="col">
                                <div>
                                    <label class="col-form-label">
                                        Email <span class="text-danger">*</span>
                                    </label>
                                    <input readonly type="text" class="form-control {form.get('email').touched && form.get('email').invalid? 'is-invalid' : ''}}" formControlName="email">
                                </div>

                                <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').invalid && form.controls['email'].errors?.['required']">
                                    {{field_label['email']}} is required
                                </small>
    
                                <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').invalid && form.controls['email'].errors?.['pattern']">
                                    {{field_label['email']}} is invalid format
                                </small>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col">
                                <div>
                                    <label class="col-form-label">
                                        Name <span class="text-danger">*</span>
                                    </label>
                                    <input readonly type="text" class="form-control {{form.get('name').touched && form.get('name').invalid? 'is-invalid' : ''}}" formControlName="name">
                                </div>

                                <small class="text-danger" *ngIf="form.get('name').touched && form.get('name').invalid && form.controls['name'].errors?.['required']">
                                    {{field_label['name']}} is required
                                </small>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col">
                                <div>
                                    <label class="col-form-label">
                                        Message <span class="text-danger">*</span>
                                    </label>
                                    <textarea rows="5" class="form-control {{form.get('message').touched && form.get('message').invalid? 'is-invalid' : ''}}" formControlName="message"></textarea>
                                </div>

                                <small class="text-danger" *ngIf="form.get('message').touched && form.get('message').invalid && form.controls['message'].errors?.['required']">
                                    {{field_label['message']}} is required
                                </small>
                            </div>
                        </div>

                        
                        
                        <div class="row align-items-center">
                            <div class="col-xxl-3 col-xl-3 col-auto">
                                <small class="fw-bold font-16">
                                    Upload attachment/s
                                </small><br/>
                               
                                <small>
                                    Max size : 25Mb
                                </small>

                            </div>
                            <div class="col-xxl-6 col-xl-6 col-auto">
                                <div>
                                    <span class="d-inline-block file-text-truncate">
                                        <input accept=".docx, .doc, .xlsx, .xls, .csv, .pdf, .png, .jpg, .jpeg, .svg" (change)="upload($event)" type="file" multiple class="custom-file-input" formControlName="attachment">
                                    </span>
                                </div>
                            </div>


                            <div class="col" *ngIf="show_add_btn">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-primary font-700" (click)="submit()">Submit</button>
                                </div>
                                  
                            </div>
                        </div>

                        <div class="row" *ngIf="totalFileSize > 0">
                            <div class="col-xxl-3 col-xl-3 col-auto">
                                <small class="fw-bold">Total Size : {{ commonService.catchIsNaN(totalFileSize,2)}} MB</small>
                            </div>

                            <div class="col-xxl-5 col-xl-5 col-auto">
                                <small class="text-danger" *ngIf="form.get('attachment').touched && form.get('attachment').invalid && form.controls['attachment'].errors?.['exceed_file_size']">
                                    {{field_label['attachment']}} max size exceeded!
                                </small>

                                <small class="text-danger" *ngIf="form.get('attachment').touched && form.get('attachment').invalid && form.controls['attachment'].errors?.['invalid_file']">
                                    {{field_label['attachment']}} contains invalid file type!
                                </small>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-xxl-3 col-xl-3 col-auto">
                                <small class="fw-bold text-success">
                                    Allowed file
                                </small>
                            </div>
                            <div class="col-auto">
                                <small class="text-success fw-bold">docx | 
                                    doc | 
                                    xlsx | 
                                    xls | 
                                    csv | 
                                    pdf | 
                                    png | 
                                    jpg | 
                                    jpeg | 
                                    svg
                                </small>
                            </div>
                        </div>
                        
                    </form>

                </div>
            </div>
        </div>
    </div>
    
</div>