import { Component } from '@angular/core';

@Component({
  selector: 'app-bulk-search-export',
  templateUrl: './bulk-search-export.component.html',
  styleUrls: ['./bulk-search-export.component.scss']
})
export class BulkSearchExportComponent {

}
