import { Component, ViewChild } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { Globals } from '../global/global-variable';
import { LoginService } from '../services/login-service';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { MultifactorAuth } from '../services/2fa-service';
import { multiFactor,getMultiFactorResolver,PhoneAuthProvider, getAuth,RecaptchaVerifier } from '@angular/fire/auth';
import { FirebaseApp } from '@angular/fire/app';
@Component({
  selector: 'app-login',
  
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    form = this.fb.group({
        email: ['', [Validators.required,Validators.pattern('[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
        password: ['',Validators.required],
        remember_me : [false]
    });

    field_label = {
        email       : "Email address",
        password    : "Password",
        otp1 : "OTP",
        otp2 : "OTP",
        otp3 : "OTP",
        otp4 : "OTP",
        otp5 : "OTP",
        otp6 : "OTP"
    }

    show_password = false;

    //Get Current Year
    public year_today;
    public date_today;
    public recaptchaValidated = false;
    recaptchaVerifier : any;


    //otp
    @ViewChild('otp_button') otp_button;
    @ViewChild('otp1') otp1;
    @ViewChild('otp2') otp2;
    @ViewChild('otp3') otp3;
    @ViewChild('otp4') otp4;
    @ViewChild('otp5') otp5;
    @ViewChild('otp6') otp6;
    otp_form = this.fb.group({
        otp1: ['',[Validators.required,Validators.maxLength(1)]],
        otp2: ['',[Validators.required,Validators.maxLength(1)]],
        otp3: ['',[Validators.required,Validators.maxLength(1)]],
        otp4: ['',[Validators.required,Validators.maxLength(1)]],
        otp5: ['',[Validators.required,Validators.maxLength(1)]],
        otp6: ['',[Validators.required,Validators.maxLength(1)]],
    });
    otp_min = 300;
    timeLeft: number = 300; // 5 minutes in seconds
    timer: any;
    user_id = ''


    constructor(
        public global           : Globals,
        private router          : Router,
        private fb              : FormBuilder,
        private toastr          : ToastrService,

        private loginService    : LoginService,
        private multifactorAuth : MultifactorAuth
        ){

        //Get Current Year
        this.date_today = new Date()
        this.year_today = this.date_today.getFullYear();

        
        
    }

    ngOnInit(): void {
       
        
        
        
        if(localStorage.getItem('remember_me') !== null){
            const remember_me_arr   = JSON.parse(localStorage.getItem('remember_me') as string);
            
            if(remember_me_arr.remember_me){
                this.form.controls['remember_me'].setValue(remember_me_arr.remember_me);
                this.form.controls['email'].setValue(remember_me_arr.user_name);
            }//end if
        }//end if

        if(this.global.login_session !== null && this.global.login_session["id"] !== undefined){
            window.location.replace('/main/dashboard');
        }//end if
    }

    login(){
        this.global.loading = true;
        const val = this.form.value;

        

        try{
            //validate login
            let error_html_arr = new Array();

            
            if(!this.form.valid){
                //this.form.controls['email'].setErrors({'user_not_found': false});
                //this.form.controls['email'].setErrors({'password_incorrect': false});

                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['user_not_found'] !== undefined && field.errors?.['user_not_found']){
                        error_html_arr.push('The email you entered did not match our records. Please double-check and try again. <br/>');
                    }//end if

                    if(field.errors?.['password_incorrect'] !== undefined && field.errors?.['password_incorrect']){
                        error_html_arr.push('Incorrect password. Please try again.<br/>');
                    }//end if
                }//end for
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if
            
            var test_param = {
                "username" : val.email,
                "password" : val.password
            }
            this.loginService.login(test_param).subscribe((response) => {
                console.log('new version',response);

                if(val.remember_me){
                    const remember_me_arr = {
                        "remember_me" : val.remember_me,
                        "user_name" : val.email
                    }
                    localStorage.setItem('remember_me',JSON.stringify(remember_me_arr));
                }else{
                    if(localStorage.getItem('remember_me') !== null){
                        localStorage.removeItem('remember_me');
                    }//end if
                }//end if

                if(response["success"]){
                    console.log('rrr',response["data"]);
                    if(response["data"]['enableMFA'] !== undefined && response["data"]['enableMFA']){
                        this.startTimer();
                        this.user_id = response["data"]["id"];
                        this.otp_button.nativeElement.click();
                    }else{
                        //create session
                        //console.log(response["data"]);
                        localStorage.setItem('accessToken',response["data"]["fb_profile"]["idToken"]);
                        const now = new Date() as any;
                        const now_time = now.getTime();
                        localStorage.setItem('accessToken_start',now_time);
                        localStorage.setItem('date_set_time',now_time);
                        localStorage.setItem('session_start',now_time);
                        localStorage.setItem("token_start",now_time);

                        localStorage.setItem("login_session",JSON.stringify(response["data"]));
                        //end create session
                        console.log("Successfully Logged In!");
                        //this.toastr.success("Successfully Logged In!","System Message");
                    
                        //console.log(response.data)
                        window.open('/main/dashboard',"_self");
                    }//end if
                    
                    

                    
                    
                }else{
                    const errObj = {
                        code : response["data"]["error"]["code"],
                        message : response["data"]["error"]["message"],
                    }//end 
                  

                    /*if(JSON.stringify(response["data"]["message"]).includes('auth/user-not-found')){
                        this.form.controls['email'].setErrors({'user_not_found': true});
                    }else if(JSON.stringify(response["data"]["message"]).includes('auth/wrong-password')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else if(JSON.stringify(response["data"]["message"]).includes('auth/invalid-login-credentials')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else{
                        this.toastr.error(response.data["message"],"System Message");
                    }//end if
                    */


                    if(JSON.stringify(response["data"]["error"]["message"]).toLowerCase().includes('email_not_found') || JSON.stringify(response["data"]["error"]["message"]).toLowerCase().includes('user not found')){
                        this.form.controls['email'].setErrors({'user_not_found': true});
                    }else if(JSON.stringify(response["data"]["error"]["message"]).toLowerCase().includes('invalid_password')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else if(JSON.stringify(response["data"]["error"]["message"]).includes('auth/invalid-login-credentials')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else{
                        this.toastr.error(response["data"]["error"]["message"],"System Message");
                    }//end if
                    

                    
                    //throw errObj;
                    
                }//end if
                this.global.loading = false;
            })

            /*this.loginService.signIn(val).then((response) => {

                if(val.remember_me){
                    const remember_me_arr = {
                        "remember_me" : val.remember_me,
                        "user_name" : val.email
                    }
                    localStorage.setItem('remember_me',JSON.stringify(remember_me_arr));
                }else{
                    if(localStorage.getItem('remember_me') !== null){
                        localStorage.removeItem('remember_me');
                    }//end if
                }//end if

                
                if(response.success){
                    //create session
                    console.log(response.data)
                    const now = new Date() as any;
                    const now_time = now.getTime();
                    localStorage.setItem('date_set_time',now_time);
                    localStorage.setItem('session_start',now_time);
                    localStorage.setItem("token_start",now_time);

                    localStorage.setItem("login_session",JSON.stringify(response.data));
                    //end create session
                    console.log("Successfully Logged In!");
                    //this.toastr.success("Successfully Logged In!","System Message");
                 
                    //console.log(response.data)
                    //window.open('/main/dashboard',"_self");
                    
                }else{
                    const errObj = {
                        code : response.data["code"],
                        message : response.data["message"],
                    }//end 
                  

                    if(JSON.stringify(response["data"]["message"]).includes('auth/user-not-found')){
                        this.form.controls['email'].setErrors({'user_not_found': true});
                    }else if(JSON.stringify(response["data"]["message"]).includes('auth/wrong-password')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else if(JSON.stringify(response["data"]["message"]).includes('auth/invalid-login-credentials')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else{
                        this.toastr.error(response.data["message"],"System Message");
                    }//end if

                    

                    
                    //throw errObj;
                    
                }//end if
                this.global.loading = false;
            });
            */
        }catch(e : any){
            //this.toastr.error(e.message,"System Message",{enableHtml : true});
            console.log(e.message);
            this.global.loading = false;
        }//end try
        
        //alert(val.email);
        //this.global.loading = false;
    }//end if

    s_password(name){
        
        if(name == 'password'){
            if(this.show_password){
                this.show_password = false;
            }else{
                this.show_password = true;
            }//end if
            
        }//end if
    }


    //----------------------------------------------------otp----------------------------------------------------
    handle_focus(step){
        
        //document.getElementById('otp'+step).focus();        
    }

    select_text(id){
        if(id == 'otp1'){
            this.otp1.nativeElement.select();
        }else if(id == 'otp2'){
            this.otp2.nativeElement.select();
        }else if(id == 'otp3'){
            this.otp3.nativeElement.select();
        }else if(id == 'otp4'){
            this.otp4.nativeElement.select();
        }else if(id == 'otp5'){
            this.otp5.nativeElement.select();
        }else if(id == 'otp6'){
            this.otp6.nativeElement.select();
        }//end if
    }

    pasteOtp(event: ClipboardEvent) {
        const otp_frm = this.otp_form;
        const clipboardData = event.clipboardData;
        const pastedData = clipboardData?.getData('text') || '';
        
        // Split the pasted OTP into individual digits
        const otpDigits = pastedData.split('').slice(0, 6); // Adjust slice according to your needs
        
        console.log('otpDigits',otpDigits);
        

        // Set the value of each input field
        

        otpDigits.forEach((digit, index) => {
            let input;

            const i = (index+1);
            if(i == 1){
                input = otp_frm.controls['otp'+i];   
            }else if(i == 2){
                input = otp_frm.controls['otp'+i];   
            }else if(i == 3){
                input = otp_frm.controls['otp'+i];  
            }else if(i == 4){
                input = otp_frm.controls['otp'+i];    
            }else if(i == 5){
                input = otp_frm.controls['otp'+i]; 
            }else if(i == 6){
                input = otp_frm.controls['otp'+i];
            }//end if

            if (input) {
                input.setValue(digit);
               
            }//end if
        });
    }

    startTimer() {
        this.timer = setInterval(() => {
            if (this.timeLeft > 0) {
            this.timeLeft--;
            } else {
            clearInterval(this.timer);
            }
        }, 1000); // Update every second
    }

    get formattedTime() {
        const minutes = Math.floor(this.timeLeft / 60);
        const seconds = this.timeLeft % 60;
        return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
    }

    padZero(num: number) {
        return num < 10 ? '0' + num : num;
    }


    async submit_otp(){
        this.global.loading = true;
        const val = this.otp_form.value;
        const login_frm = this.form.value;
        const otp = val.otp1.toString()+val.otp2.toString()+val.otp3.toString()+val.otp4.toString()+val.otp5.toString()+val.otp6.toString();

        try{
            //-----------------------------------validate login-----------------------------------
            let error_html_arr = new Array();
            if(!this.otp_form.valid){
                for(var k in this.otp_form.controls){
                    var field = this.otp_form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['user_not_found'] !== undefined && field.errors?.['user_not_found']){
                        error_html_arr.push('The email you entered did not match our records. Please double-check and try again. <br/>');
                    }//end if

                    if(field.errors?.['password_incorrect'] !== undefined && field.errors?.['password_incorrect']){
                        error_html_arr.push('Incorrect password. Please try again.<br/>');
                    }//end if

             
                }//end for
            }//end if

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "validation_error",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

            const request_param = {
                "username" : login_frm.email,
                "password" : login_frm.password,
                "otp" : otp
            };

            console.log('request_param',request_param);
            const result = await lastValueFrom(this.multifactorAuth.verify_otp(request_param));
            if(result["success"]){
                //create session
                //console.log(response["data"]);
                localStorage.setItem('accessToken',result["data"]["fb_profile"]["idToken"]);
                const now = new Date() as any;
                const now_time = now.getTime();
                localStorage.setItem('accessToken_start',now_time);
                localStorage.setItem('date_set_time',now_time);
                localStorage.setItem('session_start',now_time);
                localStorage.setItem("token_start",now_time);

                localStorage.setItem("login_session",JSON.stringify(result["data"]));
                //end create session
                console.log("Successfully Logged In!");
                //this.toastr.success("Successfully Logged In!","System Message");
            
                //console.log(response.data)
                window.open('/main/dashboard',"_self");
            }else{
                console.log(result["data"]);
                let message = result["data"]["error"]["message"];
                if(message === undefined){
                    message = result["data"]["error"]["code"];
                }//end if
                this.toastr.error(message,"System Message!");
                
            }
            //-----------------------------------validate login-----------------------------------

            this.global.loading = false;
        }catch(e){
            console.log(e.message);
            //this.toastrService.error(e.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end if
    }//end

    async resend_otp(){
        this.global.loading = true;
        const val = this.form.value;
        //-----------------------------------login-----------------------------------
        const request_param = {
            "username" : val.email,
            "password" : val.password
        }
       
        const result = await lastValueFrom(this.multifactorAuth.generate_otp(request_param));
        
        if(result["success"]){
            this.timeLeft = this.otp_min;
            this.startTimer();
            //this.otp_button.nativeElement.click();
            
        }else{
            console.log(result["data"]);
            let message = result["data"]["error"]["message"];
            if(message === undefined){
                message = result["data"]["error"]["code"];
            }//end if
            this.toastr.error(message,"System Message!");
            
        }

        this.global.loading = false;
        //-----------------------------------login-----------------------------------
    }

    //----------------------------------------------------otp----------------------------------------------------

    
}//end main
