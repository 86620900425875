
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { filter, lastValueFrom, Observable,Subscription, interval } from "rxjs";
import { environment } from 'src/environments/environment';
import { CommonService } from "../library/pcl_lib";


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth,browserLocalPersistence, signInWithEmailAndPassword,signOut } from "firebase/auth";
import { setPersistence } from "@firebase/auth";
import { Auth, createUserWithEmailAndPassword, deleteUser, idToken } from "@angular/fire/auth";
import { UserManagementService } from "./user-management-service";

import { FirebaseApp } from "@angular/fire/app";
import { Globals } from "../global/global-variable";

@Injectable({
    providedIn : "root"
})


export class LoginService {
    //private app           = initializeApp(environment.firebaseConfig);
    //private analytics     = getAnalytics(this.app);

    auth : Auth;
    public tokenRefreshSubscription: Subscription;
    constructor(private router: Router,
        private http: HttpClient,
        private afApp : FirebaseApp,
        private global : Globals,
        private userManagementService : UserManagementService,
        private lib : CommonService){     

        this.auth = getAuth(afApp);
    }

    public login(q): Observable<result_data>{
        let response = {
            success : false,
            data : {}
        }//end

        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+environment.web_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.sign_in,q,requestOptions);

    }

    public getToken(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+environment.web_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.get_custom_token,q,requestOptions);

    }

    async signIn(param){
        let response = {
            success : false,
            data : {}
        }//end
       await setPersistence(this.auth,browserLocalPersistence)
        .then(async () =>{
            await signInWithEmailAndPassword(this.auth,param.email, param.password)
            .then(async (userCredential) => {
                

                //fetch user on firebase
                let query_param = {
                    "record_type" : environment.user_management,
                    "pageSize" : 1,
                    "filterFieldArr" : [
                        "username"
                    ],
                    "filterOperatorArr" : [
                        "=="
                    ],
                    "filterValueArr" : [
                        param.email
                    ]
                }//end

                
        
                //return this.settingService.filterList(query_param);

                try {
                    console.log('adas')
                    await this.auth.currentUser?.getIdToken(true).then(idToken => {
                        /*this.global.login_session = {
                            'accessToken' : idToken
                        }*/

                        localStorage.setItem('accessToken',idToken);
                        //this.global.login_session['accessToken'] = idToken;
                        
                    });
                    

                   

                    const fb_user       = await lastValueFrom(this.userManagementService.filterList(query_param));
                    const fb_data       = fb_user["data"]["query_data"][0];
                    
                  
                    

                    query_param         = {
                        "record_type" : environment.user_permission,
                        "pageSize" : 1,
                        "filterFieldArr" : [
                            "__name__"
                        ],
                        "filterOperatorArr" : [
                            "=="
                        ],
                        "filterValueArr" : [
                            fb_data["user_type"]
                        ]
                    }
                    var permission              = {};
                    permission["permission"]    = await lastValueFrom(this.userManagementService.filterList(query_param));
                    permission["permission"]    = permission["permission"]["data"]["query_data"][0];
                    //console.log(permission["permission"]);
                    if(fb_user["success"]){
                        if(fb_user["data"]["query_result"] > 0){
                            

                            //response.data       = userCredential.user;
                            //await this.auth.currentUser?.getIdToken(true).then(idToken => {
                                //response.data["accessToken"] = idToken;
                                response.data = {
                                    //"accessToken" : idToken,
                                    ...userCredential.user,
                                    ...fb_user["data"]["query_data"][0],
                                    ...permission
                                }

                                console.log(response.data);
                            
                            //});

                            //update login
                            /*const request_param = {
                                "records" : [{
                                    "record_type"   : environment.user_management,
                                    "action" : "update",
                                    "id"  : fb_data["id"],
                                    "logged_in" : true
                                }]
                            };
                            const api = await lastValueFrom(this.userManagementService.save_data(request_param));
                            */
                            //ennd update login
                            response.success    = true;
                        }else{
                            response.success    = false;
                            response.data       = {
                                code : "INVLD_LOGIN",
                                message : "Invalid Login"
                            };
                        }//end if
                    }else{
                        response.success    = false;
                        response.data       = {
                            code : "FIREBASE_ERROR",
                            message : fb_user["data"]
                        };
                    }//end if
                } catch (error : any) {
                    response.success    = false;
                    response.data       = {
                        code : "UNEXPECTED_ERROR",
                        message : error.message
                    };
                }//end try


                

               
                //end fetch user on firebase

                
            })
            .catch((error) => {
                response.success    = false;
                response.data       = error;
            });
        
            
        })

        
        return response;
        
    }//end

   async signOut(){
        let response = {
            success : false,
            data : {}
        }//end

       
        

       await signOut(this.auth).then(() => {
            response.success    = true;
            response.data       = {};
            
        })
        .catch((error) => {
            response.success    = false;
            response.data       = error;
        });

        return response;

    }//end

    public createUserV2(q): Observable<result_data>{
        let response = {
            success : false,
            data : {}
        }//end

        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+environment.web_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.create_user,q,requestOptions);

    }

    async createUser(param){
        let response = {
            success : false,
            data : {}
        }//end

        console.log('parampcl',param);
        try {
            console.log(param);
            if(param.records[0].action == "add"){
                //--------------------------check user if existing on firebase--------------------------
                const query_param = {
                    "record_type" : environment.user_management,
                    "pageSize" : 1,
                    "filterFieldArr" : ["username"],
                    "filterOperatorArr" : ["=="],
                    "filterValueArr" : [param.records[0].username]
                }//end
                var new_user = false;
                var resp = await lastValueFrom(this.userManagementService.filterList(query_param));
                if(resp["success"]){
                    if(resp["data"]["total_result"] <= 0){
                        new_user = true;
                    }//end if
                }//end if

                if(new_user){
                    await signInWithEmailAndPassword(this.auth, param.records[0].username, param.records[0].password).then(async cred => {
                        await deleteUser(cred.user).then(() => {

                        }).catch((e1) => {
                            const errObj = {
                                code : "deleteUser Error",
                                message : e1
                            }
                            throw errObj;
                        });
                      
                     }).catch((e2) => {
                        console.log('add doesnt exist',e2);
                    });;
                     
                }//end if
                


                
                //--------------------------check user if existing on firebase--------------------------


            }else{
               await signInWithEmailAndPassword(this.auth, param.records[0].username_placeholder, param.records[0].password_placeholder).then(async cred => {
                   await deleteUser(cred.user).then(() => {
                    }).catch((e) => {
                        const errObj = {
                            code : "deleteUser Error",
                            message : e
                        }
                        throw errObj;
                    });
                }).catch((e) => {
                    throw e;
                });
            }//end if

           await createUserWithEmailAndPassword(this.auth,param.records[0].username,param.records[0].password)
                .then(async (userCredential) => {
                    

                    for(const k in param.records[0]){
                        if(k.includes("_placeholder")){
                            delete param.records[0][k];
                            
                        }//end if
                    }//end
                    
                 var api = await lastValueFrom(this.userManagementService.save_data(param));
                 if(api["success"]){
                    response.success    = true;
                    response.data       = {};
                }else{
                    const errObj = {
                        code : "Firebase Error",
                        message : api["data"]["message"]
                    }
                    throw errObj;
                }//end if
                 

                }).catch((e) => {
                    

                    throw e;
                });
        } catch (error : any) {
            response.success    = false;
            response.data       = error;
        }//end try
        return response;
    }//end

    async delete_firebase_user(param){
        let response = {
            success : false,
            data : {}
        }//end

        try {
            await signInWithEmailAndPassword(this.auth, param.records[0].username, param.records[0].password).then(async cred => {
                await deleteUser(cred.user).then(async () => {
                    const api = await lastValueFrom(this.userManagementService.save_data(param));
                    if(api["success"]){
                        response.success    = true;
                        response.data       = {};
                    }else{
                        const errObj = {
                            code : "Firebase Error",
                            message : api["data"]["message"]
                        }
                        throw errObj;
                    }//end if
                    
                }).catch((e) => {
                    const errObj = {
                        code : "deleteUser Error",
                        message : e
                    }
                    throw errObj;
                });
            }).catch((e) => {
                const errObj = {
                    code : "signInWithEmailAndPassword Error",
                    message : e
                }
                throw errObj;
            });
        }catch (error : any) {
            response.success    = false;
            response.data       = error;
        }//end try
        return response;
    }

    public save_data(function_param): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            //headers: new HttpHeaders().append('Authorization', 'Bearer '+localStorage.getItem("accessToken")),
            responseType: 'json',
            headers : headers
        }//end
        return  this.http.put(environment.save_data_staging,function_param,requestOptions);
    }//end

    public filterList(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.get_data_staging,q,requestOptions);
    }
}
export interface result_data {}