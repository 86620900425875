import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { CommonService } from "../library/pcl_lib";
import { Globals } from "../global/global-variable";
@Injectable({
    providedIn : "root"
})

export class AssignTrialSubscriptionService {
    constructor(private router: Router,
        private http: HttpClient,
        private global : Globals,
        private lib : CommonService){        
    }

    


    public create_trial_subscription(function_param): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            headers: headers,
            responseType: 'json'
        }//end
        return  this.http.post(environment.create_trial_subscription,function_param,requestOptions);
    }//end

    
   
}

export interface result_data {}