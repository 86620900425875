<div class="bg">
    
    <div class="container" >
        <div class="row justify-content-center align-items-center " style="min-height: 100vh;">
            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6">
                <div class="row justify-content-center py-5">
                    
                    <img src="../../assets/images/uchech logo.svg" class="col-6 img-fluid">
                    
                </div>
                
                <div class="card">
                    <div class="card-body px-5 py-5" *ngIf="show_success_message">
                        <h2 class="text-center fw-bolder">Email Sent</h2>
                        <div class="text-center fs-6">
                            We’ve sent you instructions to recover your password in your email.
                        </div>

                        <div class="d-grid gap-2 mt-5">
                            <a href="login" class="btn btn-login text-white">Back to log in</a>
                        </div>
                    </div>

                    <div class="card-body px-5 py-5" *ngIf="!show_success_message">
                        <h2 class="text-center fw-bolder">Reset Password</h2>
                        <div class="text-center fs-6">
                            Enter your email below and we'll send you
                        </div>
                        <div class="text-center fs-6">
                            instruction on how to reset your password
                        </div>
                        <form class="my-3" [formGroup]="form">
                            <div class="mb-3">
                                <label for="email" class="form-label"><small class="fw-semibold">Email</small></label>
                                <div class="input-group has-validation">
                                    <input  formControlName="email" type="email" class="form-control {{form.get('email').touched && form.get('email').invalid? 'is-invalid' : ''}}">
                                    
                                </div>

                                <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').invalid && form.controls['email'].errors?.['required']">
                                    {{field_label['email']}} is required
                                </small>

                                <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').invalid && form.controls['email'].errors?.['pattern']">
                                    {{field_label['email']}} format is invalid
                                </small>

                                <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').invalid && form.controls['email'].errors?.['user_not_found']">
                                    The email you entered did not match our records. Please double-check and try again.
                                </small>

                                

                            </div>
                            
                        </form>

                        <div class="d-grid gap-2">
                            <button class="btn btn-login text-white" type="button" (click)="reset_password()">Reset Password</button>
                            <a [routerLink]="['/login']" class="btn btn-outline-login" >Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>