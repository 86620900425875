
<div class="container-fluid">
    <div class="row justify-content-end ms-3 me-3">
        <div class="col col-auto" *ngIf="show_add_btn">
            <div class="d-grid gap-2">
                <button class="btn btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#modal_view">New Source</button>
            </div>
            
        </div>
    </div>

    

    

    <div class="row justify-content-center ms-3 me-3">
        <div class="col">
            <p class="text-muted"></p>
            <div class="table-responsive shadow bg-white rounded" >
                <table class="table align-middle" style="min-width:2in;">
                    <thead>
                        <tr>
                            <th class="text-muted px-3"><small>Source</small></th>
                            <th class="text-muted px-3"><small>Link</small></th>
                            <th class="text-muted px-3"><small>Last Update Date</small></th>
                            <th colspan="3" class="text-muted px-3"><small>Action</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of table_list; let x = index">
                            
                        <td class="text-left px-3"><small>{{data.source}}</small></td>
                        <td class="text-left px-3"><small>{{data.source_link}}</small></td>
                        <td class="text-left px-3"><small>{{data.last_update_date_parsed}}</small></td>
                        <td style="width:0.6in;" class="text-left">
                            <div class="d-grid gap-2" *ngIf="show_edit_btn">
                                <img title="Edit" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                            </div>
                        </td>

                        <td style="width:0.6in;" class="text-center">
                            <img  data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)"  src="../../assets/images/delete.svg" />
                        </td>
                        
                        <td style="width:1in;" class="text-left">
                            <div class="d-grid gap-2" *ngIf="show_sync_btn">
                                <button class="btn btn-primary btn-sm font-700" type="button" (click)="sync_data(data)" [disabled]="data.synced">Sync</button>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row justify-content-end ms-3 me-3 mt-3 d-none">
        
        <div class="col col-auto">
            <form [formGroup]="form2">
                <div>
                    <input accept=".docx, .doc, .xlsx, .xls, .csv, .pdf, .png, .jpg, .jpeg, .svg" (change)="upload($event)" type="file" multiple class="custom-file-input" formControlName="attachment">
                </div>
            </form>
            
        </div>
    </div>

    <form [formGroup]="form_upload" class="mt-3">
        <div class="row align-items-center justify-content-end s-3 me-3 mt-3">
            <div class="col-xxl-2 col-xl-2 col-auto">
                <small class="fw-bold font-16">
                    Upload file (Max size : 25Mb)
                </small>
            </div>

            <div class="col-xxl-2 col-xl-2 col-auto">
                <small class="fw-bold font-16">
                    <select class="form-select" formControlName="type">
                        <option value="individual">Individual</option>
                        <option value="entity">Corporation</option>
                    </select>
                </small>
            </div>
            <div class="col-xxl-3 col-xl-3 col-auto">
                <div>
                    <span class="d-inline-block file-text-truncate">
                        <input accept=".csv" (change)="upload_csv($event)" type="file" multiple class="custom-file-input" formControlName="attachment">
                    </span>
                </div>
            </div>


            <div class="col-auto" *ngIf="show_add_btn">
                <div class="d-grid gap-2">
                    <button class="btn btn-primary" (click)="upload_file()" >Upload</button>
                </div>
                
            </div>
        </div>

        <div class="row justify-content-end s-3 me-3 mt-3" *ngIf="totalFileSize > 0">
            <div class="col-xxl-2 col-xl-2 col-auto">
                <small class="fw-bold">Total Size : {{ commonService.catchIsNaN(totalFileSize,2)}} MB</small>
            </div>

            <div class="col-xxl-3 col-xl-3 col-auto">
                <small class="text-danger" *ngIf="form_upload.get('attachment').touched && form_upload.get('attachment').invalid && form_upload.controls['attachment'].errors?.['exceed_file_size']">
                    {{field_label['attachment']}} max size exceeded!
                </small>

                <small class="text-danger" *ngIf="form_upload.get('attachment').touched && form_upload.get('attachment').invalid && form_upload.controls['attachment'].errors?.['invalid_file']">
                    {{field_label['attachment']}} contains invalid file type!
                </small>
            </div>

            
        </div>

        <div class="row align-items-center justify-content-end s-3 me-3 mt-3">
            <div class="col-auto">
                <small class="fw-bold text-success">
                    Allowed file
                </small>
            </div>
            <div class="col-xxl-3 col-xl-3 col-auto">
                <small class="text-success fw-bold">csv
                </small>
            </div>
        </div>
    </form>
</div>


<!-- Modal -->
<div  class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="modal_view" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Source</h1>
            <button #modal_view_close_button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="form" class="py-3">

                <div class="mb-3 row d-none">
                    <label class="col-sm-2 col-form-label">Action</label>
                    <div class="col-sm-10">
                        <input type="text" readonly class="form-control-plaintext" formControlName="action">
                    </div>
                </div>

                <div class="mb-3 row d-none">
                    <label class="col-sm-2 col-form-label">Source ID</label>
                    <div class="col-sm-10">
                        <input type="text" readonly class="form-control-plaintext" formControlName="id">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-2 col-form-label">Source</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control {{form.get('source').touched && form.get('source').invalid? 'is-invalid' : ''}}" formControlName="source">
                        
                        <small class="text-danger" *ngIf="form.get('source').touched && form.get('source').invalid && form.controls['source'].errors?.['required']">
                            {{field_label['source']}} is required
                        </small>
                    </div>

                    
                </div>

                <div class="mb-3 row">
                    <label class="col-sm-2 col-form-label">Source Link</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control {{form.get('source_link').touched && form.get('source_link').invalid? 'is-invalid' : ''}}" formControlName="source_link">
                        <small class="text-danger" *ngIf="form.get('source_link').touched && form.get('source_link').invalid && form.controls['source_link'].errors?.['required']">
                            {{field_label['source_link']}} is required
                        </small>
                    
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary font-700" (click)="save_data()">Save changes</button>
        </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmation_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                <button type="button" class="btn-close" #confirmation_close_button data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center align-items-center mb-3">
                    <div class="col-auto">
                        <img style="width:0.8in;height:0.8in;" class="img-fluid mx-auto d-block" src="../../assets/images/delete-icon.png" >
                    </div>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <p class="text-center">
                            <small class="col-form-label fw-bold" style="font-size: 9pt !important;">Are you sure you want to delete this source?</small>
                        </p>
                    </div>
                </div>
                

                <div class="row justify-content-center my-3">
                    <div class="col-xxl-5 col-xl-5 col-auto">
                        <div class="d-grid gap-2">
                        
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" (click)="close()" class="btn btn-outline-primary btn-sm">Back</button>
                        </div>
                    </div>
                    
                    <div class="col-xxl-5 col-xl-5 col-auto">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary btn-sm" (click)="delete_data(for_delete)">Delete</button>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </div>
</div>