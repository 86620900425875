<div class="navbar_float_left v2-color-side-middle">
    <div style="min-height: 0.76in;border-bottom: 1px solid white;">
        <div class="pt-3 ps-5">
            <img class="img-fluid" style="max-height: 0.4in;" src="../../assets/images/ucheck_logo2.png">
        </div>
       
    </div>
    
    <div class="pt-3" >
        <app-side-menu></app-side-menu>
    </div>

    <div class="valign bottom">
        <div class="text-center">
            <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
            </small>
        </div>
        
    </div>
    
</div>

<div class="navbar_float_right bg-dark-green" style="min-height: 0.76in;">
    <div class="container-fluid bg-white">
        <div class="row justify-content-end">
            <div class="col-xxl-9 col-xl-9 col-lg-9 col">
                <nav class="navbar navbar-expand-md pt-3">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>

                    <div class="v2-color-side-middle offcanvas offcanvas-start collapse" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div class="offcanvas-header" style="border-bottom: 1px solid white;">
                            <div style="min-height: 0.76in;">
                                <div class="pt-3 ps-5">
                                    <img class="img-fluid" src="../../assets/images/uchech logo.svg">
                                </div>
                               
                            </div>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <app-side-menu></app-side-menu>

                            <div class="valign bottom">
                                <div class="text-center">
                                    <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                                        <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
                                    </small>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="col-auto">
                <div class="row align-items-center justify-content-end" style="min-height: 0.76in;">
                    
                    <div class="col-auto me-5">
                        <a  href="javascript: void(0)" class="navbar-brand text-black" (click)="go_back()">
                            <span class="me-2">x</span> <small>Close page</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="view-bg">
    <div class="view_bg_body">
        <!--individual-->
        <div class="row justify-content-center" *ngIf="snapshot_param['search_type'] == 'individual'">
            <div class="col-xxl-10 col-xl-10 col-lg-10">
                <div class="card shadow  mb-5">
                    <div class="card-header bg-transparent py-4 px-4">
                        
    
                        <div class="mb-3 row align-items-center">
                            <div class="col-2">
                                <img style="height:1in;" class="img-fluid" src="../../assets/images/indiv-profile-pic.png"/>
                            </div>
                            <h4 class="col text-bg-dark-green">{{data_list[0] !== undefined? data_list[0].FULL_NAME : ""}}</h4>
                            <div class="col-xl-2 col-lg-2 col-auto">
                                <div class="tab-content" id="pills-tabContent">
                                    <!-- Sanction Details -->
                                    <div *ngIf="selected_tab=='pills-home'">
                                        <button (click)="generate_report_single()" class="ms-3 btn btn-sm btn-primary font-700">Generate report</button>
                                    </div>
                                    <div *ngIf="selected_tab=='pills-adverse'">
                                        <button (click)="generate_report_adverse()" class="ms-3 btn btn-sm btn-primary font-700">Generate report</button>
                                    </div>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-5">

                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item me-2" role="presentation">
                                <button (click)="selected_t('pills-home')" class="nav-link active fw-bold" id="pills-home-tab"  data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Sanction List</button>
                            </li>
                            <li class="nav-item" role="presentation" *ngIf="this.isIncludeAdverse">
                                <button (click)="selected_t('pills-adverse')" class="nav-link fw-bold" id="pills-adverse-tab"  data-bs-toggle="pill" data-bs-target="#pills-adverse" type="button" role="tab" aria-controls="pills-adverse" aria-selected="false">Adverse Media</button>
                            </li>
                        </ul>

                       
                        <div class="tab-content" id="pills-tabContent">
                             <!-- Sanction Details -->
                             <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0" *ngFor="let data of data_list; let x = index">
                                
                                <h5 class="text-success mt-3 mb-4">Personal Details</h5>
    
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Family name</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <label class="col-form-label">{{data.FAMILY_NAME? data.FAMILY_NAME : ''}}</label>
                                    </div>
                                </div>
                                
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>First name</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <label class="col-form-label">{{data.FIRST_NAME}}</label>
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Nationality</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngFor="let alias of data.NATIONALITY_PARSED" for="staticEmail" class="col-form-label">
                                            <p *ngIf="alias !== undefined" class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
        
                                        
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Birth date</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngFor="let alias of data.INDIVIDUAL_DATE_OF_BIRTH_PARSED;" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{alias.DATE !== undefined? alias.DATE : alias.YEAR !== undefined ? alias.YEAR : ""}}
        
                                                {{alias.FROM_YEAR !== undefined? alias.FROM_YEAR : ""}} {{alias.TO_YEAR !== undefined? "-"+alias.TO_YEAR : ""}}
                                            </p>
                                        </span>
        
                                    </div>
                                </div>
                                
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Place of birth</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngFor="let alias of data.INDIVIDUAL_PLACE_OF_BIRTH_PARSED" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{alias.CITY !== undefined? alias.CITY : ''}} {{alias.STATE !== undefined? alias.STATE : ''}} {{alias.STATE_PROVINCE !== undefined? alias.STATE_PROVINCE : ''}} {{alias.PLACEOFBIRTH !== undefined? alias.PLACEOFBIRTH : ''}} {{alias.COUNTRY}}
                                            </p>
                                        </span>
                                    </div>
                                </div>
        
                                <h5 class="text-success mt-5 mb-4">Also known as</h5>
                    
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Good quality aliases</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngFor="let alias of data.INDIVIDUAL_ALIAS_PARSED" for="staticEmail" class="col-form-label">
                                            
                                            <p *ngIf="alias.QUALITY !== undefined && alias.QUALITY == 'Good'" class="col-form-label">
                                                {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                            </p>
                                        </span>
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Low quality aliases</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngFor="let alias of data.INDIVIDUAL_ALIAS_PARSED" for="staticEmail">
                                            <p *ngIf="alias.QUALITY !== undefined && alias.QUALITY == 'Low'" class="col-form-label">{{alias.ALIAS_NAME}}</p>
                                        </span>
                                    </div>
                                </div>
        
                                <h5 class="text-success mt-5 mb-4">Other details</h5>
        
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Passport number/s</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngFor="let alias of data.INDIVIDUAL_DOCUMENT_PARSED" for="staticEmail">
                                            <p *ngIf="alias.TYPE_OF_DOCUMENT !== undefined && alias.TYPE_OF_DOCUMENT.toLowerCase() == 'passport'" class="col-form-label">{{alias.NUMBER !== undefined ? alias.NUMBER : ""}}</p>
                                        </span>
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Address</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngFor="let alias of data.INDIVIDUAL_ADDRESS_PARSED" for="staticEmail">
                                            <p class="col-form-label">{{alias.STREET}} {{alias.CITY !== undefined? alias.CITY : ""}} {{alias.STATE_PROVINCE !== undefined? alias.STATE_PROVINCE : ""}} {{alias.ADDRESS1 !== undefined? alias.ADDRESS1 : ""}} {{alias.ADDRESS2 !== undefined? alias.ADDRESS2 : ""}} {{alias.ADDRESS3 !== undefined? alias.ADDRESS3 : ""}} {{alias.COUNTRY !== undefined? alias.COUNTRY : ""}}</p>
                                        </span>
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Designation</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngFor="let alias of data.DESIGNATION_PARSED" for="staticEmail">
                                            <p *ngIf="alias !== undefined" class="col-form-label">{{alias !== undefined? alias : ""}}</p>
                                        </span>
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Comments</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <label class="col-form-label">{{data.COMMENTS1}}</label>
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Name Original Script</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <label class="col-form-label">{{data.NAME_ORIGINAL_SCRIPT}}</label>
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Listed on</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <label class="col-form-label">{{data.LISTED_ON_PARSED}}</label>
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Last Updated</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngFor="let alias of data.LAST_DAY_UPDATED_PARSED" for="staticEmail">
                                            <p *ngIf="alias !== undefined" class="col-form-label">{{alias !== undefined? alias : ""}}</p>
                                        </span>
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Ref. No.</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <label class="col-form-label">{{data.REFERENCE_NUMBER}}</label>
                                    </div>
                                </div>
        
        
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Source</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <label class="col-form-label">{{data.SOURCE_LIST}}</label>
                                    </div>
                                </div>
                            </div>
                            <!-- End Sanction Details -->
                            <!-- Adverse Details -->
                            <div class="tab-pane fade" id="pills-adverse" role="tabpanel" aria-labelledby="pills-adverse-tab" tabindex="0">
                                <div class="container-fluid">
                                    <div class="row mt-5 mb-5 align-items-center">
                                        <div class="col-xxl-7 col-xl-7 col-auto">
                                            <h5>About {{commonService.toCurrency_precision(this.total_search_result,0)}} result(s)</h5>
                                        </div>
                                        <div class="col-auto">
                                            <label class="form-label">
                                                Sort By
                                            </label>
                                        </div>
                                        <div class="col-auto">
                                            <form [formGroup]="form">
                                                <select class="form-select" (change)="load_adverse()" formControlName="sort_by">
                                                    <option value="date:d">Most Recent</option>
                                                    <option value="review-rating:d">Top News</option>
                                                </select>  
                                            </form>
                                            
                                        </div>
                                    </div>
                                    
                                    <hr/>
                                    <div *ngFor="let dt of adverse_list_item; let x = index">
                                    
                                        <div class="card mb-3 border-0">
                                            <div class="row">
                                                <div class="col-auto col-form-label"><a target="_blank" href="{{dt.link}}">{{dt.title}}</a></div>
                                            </div>
                                            <div class="row g-0">
                                                <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
            
                                                    <img *ngIf="dt['pagemap'] === undefined || dt['pagemap']['cse_thumbnail'] === undefined" style="height:1in;width:1in;" src="../../assets/images/indiv-profile-pic.png" class="img-fluid" alt="...">
            
                                                    <img *ngIf="dt['pagemap'] !== undefined && dt['pagemap']['cse_thumbnail'] !== undefined" style="height:1in;width:1in;"  src="{{dt['pagemap']['cse_thumbnail'][0]['src']}}" class="img-fluid" alt="...">
                                                </div>
                                                <div class="col-xxl-10 col-xl-10 col-lg-10 col-auto">
                                                    <div class="card-body">
                                                        
                                                        <p class="card-text" [innerHTML]="dt.htmlSnippet"></p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
            
                                <div class="row container-fluid justify-content-end align-items-center mt-4 adverse">
                                    <div class="col-auto">
                                        <form [formGroup]="form" *ngIf="show_total_search_result">
            
                                            <div class="mb-3 row align-items-center">
                                                <label for="inputPassword" class="col-auto col-form-label font-14">
                                                    Rows per page
                                                </label>
                                                <div class="col">
                                                    <select style="border-style:none !important;" (change)="load_adverse()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                        <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                                    </select>
                                                </div>
                                            </div>
            
                                            
            
                                            
                                        </form>
                                    </div>
            
                                    <div class="col-auto">
                                        <div class="mb-3 row align-items-center">
                                            <div class="col-auto">
                                                {{row_counter}} - {{pagination_counter}} of {{commonService.toCurrency_precision(total_search_result,0)}}
                                            </div>
                                        </div>
                                        
                                    </div>
            
                                    <div class="col-auto">
                                        <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                            <ul class="pagination border-opacity-10">
                                                <li class="page-item " (click)="prev()" >
                                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                        <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                    </a>
                                                </li>
                                             
                                                <li class="page-item" (click)="next()">
                                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                        <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <!-- End Adverse Details -->
                        </div>                        
                    </div>
                </div>
                
                
            </div>
        </div>
        <!--individual-->
    
        <!--entity-->
        <div class="row justify-content-center" *ngIf="snapshot_param['search_type'] == 'entity'">
            <div class="col-xxl-10 col-xl-10 col-lg-10">
                <div class="card shadow  mb-5">
                    <div class="card-header bg-transparent py-4 px-4">
                        
    
                        <div class="mb-3 row align-items-center">
                            <div class="col-2">
                                <img style="height:1in;" class="img-fluid" src="../../assets/images/indiv-profile-pic.png"/>
                            </div>
                            <h4 class="col text-bg-dark-green">{{data_list[0] !== undefined? data_list[0].FULL_NAME : ""}}</h4>
                            
                        </div>
                    </div>
                    <div class="card-body px-5">
    
                        <div class="collapse-success px-3 py-3 mb-3" *ngIf="snapshot_param['view_type'] == 'sanction'">
                            <div class="row justify-content-end">
                                <div class="col text-success fw-bolder">
                                    Sanction List
                                </div>
                                <div class="col-auto">
                                    <a id="pep" (click)="hide_link($event,'pep')" data-bs-toggle="collapse" href=".pep" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                                </div>
                            </div>
                        </div>
                        
                        <div class="container-fluid pep collapse" *ngFor="let data of data_list; let x = index">
                            
                           
                            
    
                            
    
                            <h5 class="text-success mt-5 mb-4">Also known as</h5>
    
                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Good quality aliases</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngFor="let alias of data.INDIVIDUAL_ALIAS_PARSED" for="staticEmail" class="col-form-label">
                                        
                                        <p *ngIf="alias.QUALITY !== undefined && alias.QUALITY == 'Good'" class="col-form-label">
                                            {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                        </p>
                                    </span>
                                </div>
                            </div>
    
                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Low quality aliases</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngFor="let alias of data.INDIVIDUAL_ALIAS_PARSED" for="staticEmail">
                                        <p *ngIf="alias.QUALITY !== undefined && alias.QUALITY == 'Low'" class="col-form-label">{{alias.ALIAS_NAME}}</p>
                                    </span>
                                </div>
                            </div>
    
                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>a.k.a</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngFor="let alias of data.INDIVIDUAL_ALIAS_PARSED" for="staticEmail">
                                        <p *ngIf="alias.QUALITY !== undefined && alias.QUALITY == 'a.k.a'" class="col-form-label">{{alias.ALIAS_NAME}}</p>
                                    </span>
                                </div>
                            </div>
    
                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngFor="let alias of data.INDIVIDUAL_ALIAS_PARSED" for="staticEmail" class="col-form-label">
                                        
                                        <p class="col-form-label">
                                            {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                        </p>
                                    </span>
                                </div>
                            </div>
    
                           
                            
    
                            <h5 class="text-success mt-5 mb-4">Other details</h5>
    
                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Address</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngFor="let alias of data.INDIVIDUAL_ADDRESS_PARSED" for="staticEmail">
                                        <p *ngIf="alias.COUNTRY !== undefined && alias.COUNTRY !== ''" class="col-form-label">{{alias.STREET}} {{alias.CITY !== undefined? alias.CITY : ""}} {{alias.STATE_PROVINCE !== undefined? alias.STATE_PROVINCE : ""}} {{alias.COUNTRY !== undefined? alias.COUNTRY : ""}}</p>
                                    </span>
                                </div>
                            </div>
    
                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Comments</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <label class="col-form-label">{{data.COMMENTS1}}</label>
                                </div>
                            </div>
    
                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Name Original Script</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <label class="col-form-label">{{data.NAME_ORIGINAL_SCRIPT}}</label>
                                </div>
                            </div>
    
                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Listed on</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <label class="col-form-label">{{data.LISTED_ON_PARSED}}</label>
                                </div>
                            </div>
    
                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Last Updated</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngFor="let alias of data.LAST_DAY_UPDATED_PARSED" for="staticEmail">
                                        <p *ngIf="alias !== undefined" class="col-form-label">{{alias !== undefined? alias : ""}}</p>
                                    </span>
                                </div>
                            </div>
    
                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Ref. No.</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <label class="col-form-label">{{data.REFERENCE_NUMBER}}</label>
                                </div>
                            </div>
    
    
                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Source</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <label class="col-form-label">{{data.SOURCE_LIST}}</label>
                                </div>
                            </div>
                            
                
                        </div>
    
                        <!--adverse news-->
                        <div class="collapse-success px-3 py-3 mb-3" *ngIf="snapshot_param['view_type'] == 'adverse'">
                            <div class="row justify-content-end">
                                <div class="col text-success fw-bolder">
                                    Adverse News
                                </div>
                                <div class="col-auto">
                                    <a id="adverse" (click)="hide_link($event,'adverse')" data-bs-toggle="collapse" href=".adverse" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                                </div>
                            </div>
                        </div>
    
                        <!--adverse content-->
                        <div class="container-fluid adverse collapse" *ngIf="snapshot_param['view_type'] == 'adverse'">
    
                            <div class="row mt-5 mb-5 align-items-center">
                                <div class="col-xxl-7 col-xl-7 col-auto">
                                    <h5>About {{commonService.toCurrency_precision(this.total_search_result,0)}} result(s)</h5>
                                </div>
                                <div class="col-auto">
                                    <label class="form-label">
                                        Sort By
                                    </label>
                                </div>
                                <div class="col-auto">
                                    <form [formGroup]="form">
                                        <select class="form-select" (change)="load_adverse()" formControlName="sort_by">
                                            <option value="date:d">Most Recent</option>
                                            <option value="review-rating:d">Top News</option>
                                        </select>  
                                    </form>
                                    
                                </div>
                            </div>
                            <hr/>
                            
                            <div *ngFor="let dt of adverse_list_item; let x = index">
                            
                                <div class="card mb-3 border-0">
                                    <div class="row">
                                        <div class="col-auto col-form-label"><a target="_blank" href="{{dt.link}}">{{dt.title}}</a></div>
                                    </div>
                                    <div class="row g-0">
                                        <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
    
                                            <img *ngIf="dt['pagemap'] !== undefined && dt['pagemap']['cse_thumbnail'] !== undefined" style="height:1in;width:1in;"  src="{{dt['pagemap']['cse_thumbnail'][0]['src']}}" class="img-fluid" alt="...">
                                        </div>
                                        <div class="col-xxl-10 col-xl-10 col-lg-10 col-auto">
                                            <div class="card-body">
                                                
                                                <p class="card-text" [innerHTML]="dt.htmlSnippet"></p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
    
                        <div class="row container-fluid justify-content-end align-items-center mt-4 collapse adverse">
                            <div class="col-auto">
                                <form [formGroup]="form" *ngIf="show_total_search_result">
    
                                    <div class="mb-3 row align-items-center">
                                        <label for="inputPassword" class="col-auto col-form-label font-14">
                                            Rows per page
                                        </label>
                                        <div class="col">
                                            <select style="border-style:none !important;" (change)="load_adverse()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                            </select>
                                        </div>
                                    </div>
    
                                    
    
                                    
                                </form>
                            </div>
    
                            <div class="col-auto">
                                <div class="mb-3 row align-items-center">
                                    <div class="col-auto">
                                        {{row_counter}} - {{pagination_counter}} of {{commonService.toCurrency_precision(total_search_result,0)}}
                                    </div>
                                </div>
                                
                            </div>
    
                            <div class="col-auto">
                                <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                    <ul class="pagination border-opacity-10">
                                        <li class="page-item " (click)="prev()" >
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                            </a>
                                        </li>
                                     
                                        <li class="page-item" (click)="next()">
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <!--adverse content-->
    
                    </div>
                </div>
                
                
            </div>
        </div>
        <!--entity-->
    </div>
</div>

<!--
<div class="row d-none" style="min-height: 11%;">
    <div class="v2-color-side-top col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block gx-0" style="min-width: 1.2in;">
        <div class="container-fluid pt-3 ps-5">
            <img  class="img-fluid" src="../../assets/images/uchech logo.svg">
        </div>
        <hr class="text-white"/>
    </div>

    <div class="col-xxl-10 col-xl-10 col-lg-10 col">
        
        <div class="row align-items-center justify-content-end bg-dark-green" style="min-height: 0.76in;">
            <div class="col ms-5">
                
            </div>
            <div class="col-auto me-5">
                <a  href="javascript: void(0)" class="navbar-brand text-white" (click)="go_back()">
                    <span class="me-2">x</span> <small>Close page</small>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="row view-bg d-none" style="min-height: 81%;">

    <div class="v2-color-side-middle ps-4 col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block " style="min-width: 1.2in;">
        <app-side-menu></app-side-menu>
    </div>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col">
        
        
        
    </div>
</div>



<div class="row view-bg2" style="min-height: 8%;">
    <div class="v2-color-side-bottom ps-4 col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block">
        <p class="text-center">
            <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
            </small>
        </p>
    </div>
    <div class="col">

    </div>
</div>
-->