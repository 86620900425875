<div class="container-fluid">
    <div class="row mx-3">
        <div class="col">
            <div class="card shadow py-3 px-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <div class="row mb-3">
                                <div class="col">
                                    <input class="d-none" type="file" id="upload-id" accept="image/*" (change)="upload($event)" />
                                
                                    <span class="text-success fw-bold">Company Logo</span><br/>
                                    <small>Update your company logo</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col" >
                            <figcaption id="file-name" class="text-center d-none"></figcaption>
                              <div id="avatar">
                                <img id="profile_pic" src="../../assets/images/avatar.svg" class="main-profile-img" />

                                
                                <label class="document-file-name" for="upload-id">
                                    <img class="img-fluid" src="../../assets/images/ellipse.svg" />
                                 
                                </label>
                              </div>
                            
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col">
                            <span class="text-success fw-bold">Company Information</span><br/>
                        </div>
                    </div>

                    <div class="mb-3">
                        <small class="form-label text-muted">Company Name</small>
                        <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{table_list['company_name']}}">
                    </div>

                    

                    <div class="mb-3">
                        <small class="form-label text-muted">Legal Name</small>
                        <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{table_list['legal_name']}}">
                    </div>

                    <div class="mb-3">
                        <small class="form-label text-muted">Contact Number</small>
                        <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{table_list['contact_number']}}">
                    </div>

                    <div class="mb-3">
                        <small class="form-label text-muted">Email</small>
                        <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{table_list['email']}}">
                    </div>

                    <div class="mb-3">
                        <small class="form-label text-muted">Address</small>
                        <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{table_list['address']}}">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>