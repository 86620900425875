import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../global/global-variable';
import { CompanyInformationService } from '../services/company-information-service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent {
  public table_list = {};
  form = this.fb.group({
    profile_photo               : [''],
    profile_photo_placeholder   : [null]
  });

  constructor(
      private fb : FormBuilder,
      public global : Globals,
      private toastr : ToastrService,
      private  companyInfoServie : CompanyInformationService
  ){
      this.form.addValidators(
          matchValidator(this.form.controls["password"], this.form.controls["conf_password_placeholder"])
        )
  }//end constructor

  ngOnInit(){
    //check permission
    let w_access     = false;
    const permission = this.global.login_session["permission"]["company_information"]
    for(const k in permission){
        if(permission[k] == 'view'){
            w_access = true;
        }//end if

        if(permission[k] == 'edit'){
            //this.show_edit_btn = true;
        }//end if
        
    }//end for
    if(!w_access){
        window.location.replace('/main/dashboard');
    }//end if
    //end check permission
    this.load_data()
  }

  load_data(){
      this.global.loading = true;
      const query_param = {
          "record_type" : environment.company_information,
          "pageSize" : 1
      }//end

      //return this.settingService.filterList(query_param);
      this.companyInfoServie.filterList(query_param).subscribe(res => {
          console.log('res');
          console.log(res);
          if(res["success"]){
              this.table_list = res["data"]["query_data"][0];
              console.log(this.table_list)
              //populate fields
              //end populate fields
          }else{
              console.log(res);
              this.toastr.error(res["data"]["message"],"System Message");
          }//end if
          
          this.global.loading = false;
      });
  }

 

  upload(event : any){
        
    if (event.target instanceof HTMLInputElement && event.target.type === "file") {
        const fileDoc = event.target.files[0];
            //const fileName = document.getElementById("file-name");

            const f   = event.target.files[0];
            this.form.patchValue({
                profile_photo_placeholder : f
            });

            

              
            //const fileName = document.getElementById("file-name");
            

            const reader = new FileReader();
            reader.readAsDataURL(fileDoc);
            reader.onload = () => {
                const profile_pic = document.getElementById("profile_pic");
                profile_pic?.setAttribute('src', <any>reader.result);
                this.form.markAsDirty();
            }
            
            //fileName!.textContent = event.target.files[0]['name'];
    }//end if
   
}
}

function matchValidator(
  control: AbstractControl,
  controlTwo: AbstractControl
): ValidatorFn {
  return () => {
      if (control.value !== controlTwo.value)
      return {matchValidator: {value: 'Value does not match' }};
      return null;
  };
}