import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Globals } from '../global/global-variable';
import { LoginService } from '../services/login-service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    date_today = new Date()
    year_today = this.date_today.getFullYear();

    show_success_message = false;
    form = this.fb.group({
        action : ['update'],
        email : ['',[Validators.required,Validators.pattern('[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]]
        
    });

    field_label = {
        "email" : "E-mail address"
    }
    constructor(
        private toastr : ToastrService,
        private loginService : LoginService,
        public global : Globals,
        private fb : FormBuilder
    ){

    }
    reset_password(){
        this.global.loading = true;
        const val = this.form.value;


        try {
            //validate login
            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' format is invalid <br/>');
                        
                    }//end if

                    if(field.errors?.['matchValidator'] !== undefined && field.errors?.['matchValidator']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t match! <br/>');
                        
                    }//end if
                }//end for

              
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

            const query_param = {
                "record_type" : environment.user_management,
                "pageSize" : 1,
                "is_public" : true,
                "filterFieldArr" : [
                    "username"
                ],
                "filterOperatorArr" : [
                    "=="
                ],
                "filterValueArr" : [
                    val.email
                ]
            }//end

            

    
            //return this.settingService.filterList(query_param);
            this.loginService.filterList(query_param).subscribe((response) => {
                console.log('pcl',response);
                if(response["success"]){
                    
                    if(response["data"]["query_result"] > 0){
                        const query_data = response["data"]["query_data"][0];

                        const request_param = {
                            "is_public" : true,
                            "records" : [{
                                "record_type"   : environment.user_management,
                                "id" : query_data["id"],
                                "for_password_change" : true,
                                "email_option" : {
                                    "from" : environment.from_email,
                                    "to" : val.email,
                                    "template" : {
                                        "email" : val.email,
                                        "name"  : 'u_check_forgot_password',
                                        "data"  : {
                                            "first_name" : query_data["first_name"],
                                            "link" : location.origin+'/change-password/'+query_data["id"],
                                            "copyright" : this.year_today
                                        }
                                    }
                                },
                                ...val
                            }]
                        };

                        this.loginService.save_data(request_param).subscribe((res) => {
                            if(res["success"]){
                                this.show_success_message = true;
                                //this.toastr.success("Operation successfully completed!","System Message")
                            }else{
                                this.toastr.error(res["data"]["message"],"System Message"); 
                                
                            }//end if
                            this.global.loading = false;
                        })
                        
                        
                    }else{
                        

                        this.form.controls['email'].setErrors({'user_not_found': true});
                        this.global.loading = false;  
                    }//end if

                   
                }else{
                    console.log(response);
                    this.toastr.error(response["data"]["message"],"System Message");
                    this.global.loading = false;
                }//end if

                
            })

        } catch (error : any) {
            //this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try
    }
}
