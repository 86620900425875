import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  selected_event : any;
  chevron_image = "../../assets/images/chevron-down.svg"
  hide_link(event : any,id){
      var target = event.target || event.srcElement || event.currentTarget;
      //document.getElementsByClassName(id)[0].children;
      var el = document.getElementById(id);
      this.selected_event = el;
      
      if(this.selected_event.querySelector("img").src.indexOf('images/chevron-down.svg') >= 0){
        this.selected_event.querySelector("img").src = '../../assets/images/chevron-up.svg';
      }else{
        this.selected_event.querySelector("img").src = '../../assets/images/chevron-down.svg';
      }//end if
  }
}
