<div class="row justify-content-center ms-3 me-3">
    <div class="row ms-3 me-3">
        <div class="col">
           <div class="card py-2 shadow">
                <div class="card-body">
                    <form [formGroup]="form" class="py-3 px-5">
                
                        <div class="mb-3 row d-none">
                            <label class="col-sm-4 col-form-label">Action</label>
                            <div class="col-sm-8">
                                <input type="text" readonly class="form-control-plaintext" formControlName="action">
                            </div>
                        </div>
                
                        <div class="mb-3 row d-none">
                            <label class="col-sm-4 col-form-label">ID</label>
                            <div class="col-sm-8">
                                <input type="text" readonly class="form-control-plaintext" formControlName="id">
                            </div>
                        </div>
                
                        <div class="row">
                            <div class="col-12">
                                <label class="col-form-label font-12">Team Name</label>
                                <input type="text" class="form-control {{form.get('name').touched && form.get('name').invalid? 'is-invalid' : ''}}" formControlName="name">
                                <small class="text-danger" *ngIf="form.get('name').touched && form.get('name').invalid && form.controls['name'].errors?.['required']">
                                    {{field_label['name']}} is required
                                </small>
                
                                <small class="text-danger" *ngIf="form.get('name').touched && form.get('name').invalid && form.controls['name'].errors?.['pattern']">
                                    {{field_label['name']}} is invalid format
                                </small>
                            </div><!--./col-->
                        </div><!--./row-->
                        <br/>
                        <span class="text-success fw-bold font-20">Users</span><br/>
                        <small class="font-16">Select a user to be a part of your team</small>
                        <br/>
                        
                        <div class="row mt-3">
                            <div class="col-auto">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-primary font-700" data-bs-toggle="modal" data-bs-target="#modal_view">Add User <img src="../../assets/images/add.svg" /></button>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5 justify-content-center">
                            <div class="col-auto">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-primary btn-md font-700" type="button" (click)="save_data()"><small>Save Team</small></button>
                                </div>
                            </div>
                        </div>
                
                        <!--<div class="card border-0 d-none" *ngIf="total_result == 0">
                            <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
                
                                <div class="col-auto">
                                    <div class="row align-items-center justify-content-center">
                                        <div class="col-auto">
                                            <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                        </div>
                                        <div class="col-12">
                                            <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </form><!--./form-->
                </div>
            </div>
        </div>
    </div>
</div>

    
    
  



<div class="container-fluid d-none">
    <form [formGroup]="filter_form">
        <div class="row align-items-center justify-content-start ms-3 me-3">
            <div class="col-auto">
                All team(s)
            </div>
            <div class="col col-auto">
                <div class="d-grid gap-2">
                    <button type="button" *ngIf="show_add_btn" class="btn btn-primary font-700" data-bs-toggle="modal" data-bs-target="#modal_view">Add New Team <img src="../../assets/images/add.svg" /></button>
                </div>
                
            </div>
            <div class="col">
    
            </div>
            <div class="col-auto">
                <div class="input-group">
                    <span class="input-group-text bg-white">
                        <img style="width:0.15in;height:0.15in;" src="{{'../../assets/images/search-icon.svg'}}">
                    </span>
                    <input style="border-left:none;" (keyup.enter)="search()" formControlName="filter_search" (blur)="search()" placeholder="Search name"  type="text" class="form-control">
                </div>
                
            </div>
        </div>
    </form><!--./form-->

    <div class="row justify-content-center ms-3 me-3">
        <div class="col">
            <p class="text-muted"></p>
            <div class="shadow bg-white rounded" *ngIf="total_result > 0">
                <div class="table-responsive" style="max-height:500px;">
                    <table class="table align-middle" style="min-width:2in;">
                        <thead>
                            <tr>
                                <th class="text-muted text-center px-3">No.</th>
                                <th class="text-muted px-3">Name</th>
                                <th class="text-muted px-3">Total Member(s)</th>
                                <th colspan="2" class="text-muted text-center px-3">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div><!--./table-responsive-->
            </div><!--./shadow-->

            <div class="card border-0 shadow" *ngIf="total_result == 0">
                <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">

                    <div class="col-auto">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-auto">
                                <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                            </div>
                            <div class="col-12">
                                <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--./col-->
    </div><!--./row-->

</div><!--./container-fluid-->



<!-- Modal -->
<div  class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="modal_view" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
        
            <div class="modal-body">
                
                <form [formGroup]="form">
                   
                    
                    
                    <div class="table-responsive" *ngIf="total_result > 0">
                        <table class="table table-bordered align-middle" style="min-width:2in;">
                            <thead>
                                <tr>
                                    <th class="text-muted text-center px-3" style="min-width:0.5in;max-width:0.5in;">
                                        <input type="checkbox" class="form-check-input"/>
                                    </th>
                                    <th class="text-muted text-center px-3" style="min-width:0.5in;max-width:0.5in;">No.</th>
                                    <th class="text-muted px-3">Name</th>
                                    <th class="text-muted px-3">Email</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let data of table_list; let x = index">
                                    
                                    <td class="text-center px-3"><input type="checkbox" class="form-check-input" (change)="onChange(data.id)" value="{{data.id}}" /></td>
                                    <td class="text-center px-3">{{row_counter + x}}</td>
                                    <td class="text-left px-3 fw-bold">{{data.first_name}} {{data.last_name}}</td>
                                    <td class="text-left px-3">{{data.username}}</td>
                                   
                                    
                                </tr>
                                
                                
                            </tbody>
                        </table><!--./table-->
                    </div><!--./table-responsive-->

                    <div class="d-none row container-fluid justify-content-end align-items-center mt-4">
                        <div class="col-auto">
                            <form [formGroup]="filter_form">
    
                                <div class="mb-3 row align-items-center">
                                    <label for="inputPassword" class="col-auto col-form-label font-14">
                                        Rows per page
                                    </label>
                                    <div class="col">
                                        <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                            <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                        </select>
                                    </div>
                                </div>
    
                            </form>
                        </div>
    
                        <div class="col-auto">
                            <div class="mb-3 row align-items-center">
                                <div class="col-auto">
                                    {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                </div>
                            </div>
                            
                        </div>
                        
                        <div class="col-auto">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination border-opacity-10">
                                    <li class="page-item " (click)="prev()" >
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                        </a>
                                    </li>
                                 
                                    <li class="page-item" (click)="next()">
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
    
                        
                    </div>

                    <div class="card border-0" *ngIf="total_result == 0">
                        <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
        
                            <div class="col-auto">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-auto">
                                        <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                    </div>
                                    <div class="col-12">
                                        <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form><!--./form-->

                

            </div><!--./modal-body-->
        </div><!--./modal-content-->
    </div><!--./modal-dialog-->
</div><!--./modal-->
<!--modal-->