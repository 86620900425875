import { Injectable } from '@angular/core';
Injectable()
export class Globals{
    loading         = false;
    title           = "Dashboard";
    
    login_session   = null;

    constructor(){
       
        
       this.setSession(); 
    }//end constructor

    setSession(){
        if(localStorage.getItem("login_session") !== null){
            this.login_session = JSON.parse(localStorage.getItem("login_session"));
        }//end if
    }//end setSession

    clearSession(){
        if(localStorage.getItem("login_session") !== null){
            localStorage.removeItem("login_session");
            localStorage.removeItem("session_start");
            localStorage.removeItem("token_start");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("accessToken_start");
            localStorage.removeItem("date_set_time");
            this.login_session = null;
        }//end if
    }//end clearSession
}

