<div class="navbar_float_left v2-color-side-middle">
    <div style="min-height: 0.76in;border-bottom: 1px solid white;">
        <div class="pt-3 ps-5">
            <img class="img-fluid" style="max-height: 0.4in;" src="../../assets/images/ucheck_logo2.png">
        </div>
       
    </div>
    
    <div class="pt-3" >
        <app-side-menu></app-side-menu>
    </div>

    <div class="valign bottom">
        <div class="text-center">
            <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
            </small>
        </div>
        
    </div>
    
</div>


<div class="navbar_float_right bg-dark-green" style="min-height: 0.76in;">
    <div class="container-fluid bg-white">
        <div class="row justify-content-end">
            <div class="col-xxl-9 col-xl-9 col-lg-9 col">
                <nav class="navbar navbar-expand-md pt-3">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>

                    <div class="v2-color-side-middle offcanvas offcanvas-start collapse" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div class="offcanvas-header" style="border-bottom: 1px solid white;">
                            <div style="min-height: 0.76in;">
                                <div class="pt-3 ps-5">
                                    <img class="img-fluid" src="../../assets/images/uchech logo.svg">
                                </div>
                               
                            </div>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <app-side-menu></app-side-menu>

                            <div class="valign bottom">
                                <div class="text-center">
                                    <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                                        <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
                                    </small>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="col-auto">
                <div class="row align-items-center justify-content-end" style="min-height: 0.76in;">
                    
                    <div class="col-auto me-5">
                        <a  href="javascript: void(0)" class="navbar-brand text-black" (click)="go_back()">
                            <span class="me-2">x</span> <small>Close page</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="view-bg">
    <div class="view_bg_body">
        <!--individual-->
        <div class="row justify-content-center" *ngIf="snapshot_param['search_type'] == 'individual'">
            <div class="col-xxl-10 col-xl-10 col-lg-10">
                <div class="card shadow  mb-5">
                    <div class="card-header bg-transparent py-4 px-4">
                        
    
                        <div class="mb-3 row align-items-center">
                            <div class="col-2">
                                <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                    <span *ngIf="data_list[snapshot_param['id']+'-profile']['claims']['P18'] !== undefined">
                                        <img style="height:131px;width:131px;" class="img-fluid" src="{{data_list['image_url']+data_list[snapshot_param['id']+'-profile']['claims']['P18'][0]['mainsnak']['datavalue']['value']}}"/>
                                    </span>
                                    <img *ngIf="data_list[snapshot_param['id']+'-profile']['claims']['P18'] === undefined" style="height:1in;" class="img-fluid" src="../../assets/images/indiv-profile-pic.png"/>
                                </span>
                                
                            </div>
                            <h4 class="col text-bg-dark-green">{{data_list[snapshot_param['id']+"-profile"] !== undefined? data_list[snapshot_param['id']+"-profile"]["labels"]["en"]["value"] : ""}}</h4>
                            
                            <div class="col-xl-2 col-lg-2 col-auto">
                                
                                <!-- Sanction Details -->
                                <div *ngIf="selected_tab=='pills-home'">
                                    <button (click)="generate_report()" class="ms-3 btn btn-sm btn-primary font-700">Generate report</button>
                                </div>
                                <div *ngIf="selected_tab=='pills-adverse'">
                                    <button (click)="generate_report_adverse()" class="ms-3 btn btn-sm btn-primary font-700">Generate report</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-5">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item me-2" role="presentation">
                                <button (click)="selected_t('pills-home')" class="nav-link active fw-bold" id="pills-home-tab"  data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Consolidated PEP List</button>
                            </li>
                            <li class="nav-item" role="presentation" *ngIf="this.isIncludeAdverse">
                                <button (click)="selected_t('pills-adverse')" class="nav-link fw-bold" id="pills-adverse-tab"  data-bs-toggle="pill" data-bs-target="#pills-adverse" type="button" role="tab" aria-controls="pills-adverse" aria-selected="false">Adverse Media</button>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                <h5 class="text-success mt-3 mb-4">Personal Details</h5>
                            
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Family name</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <p *ngFor="let data of data_list['P734']; let x = index">
                                            <label class="col-form-label">{{data["labels"]["en"]["value"]}}</label>
                                        </p>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>First name</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <p *ngFor="let data of data_list['P735']; let x = index">
                                            <label class="col-form-label">{{data["labels"]["en"]["value"]}}</label>
                                        </p>
                                    </div>
                                </div>


                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Images</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                            <div *ngIf="data_list[snapshot_param['id']+'-profile']['claims']['P18'] !== undefined">
                                                <div class="row">
                                                    <div class="col-auto" *ngFor="let data of data_list[snapshot_param['id']+'-profile']['claims']['P18']; let x = index">
                                                        <img style="width: 131px;height:131px;" src="{{data_list['image_url']+data['mainsnak']['datavalue']['value']}}"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                        
                                    </div>
                                </div>
                                

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Country of citizenship</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <p *ngFor="let data of data_list['P27']; let x = index">
                                            <label class="col-form-label" *ngIf="data['labels']['en'] !== undefined">
                                                {{data["labels"]["en"]["value"]}}
                                            </label>

                                            
                                        </p>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Birth Date</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <p *ngFor="let data of data_list['P569']; let x = index">
                                            <label class="col-form-label">{{data["text"]}}</label>
                                        </p>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Place of Birth</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['P19'] !== undefined">
                                            <p *ngFor="let data of data_list['P19']; let x = index">
                                                <label class="col-form-label" *ngIf="data['labels']['en'] !== undefined">
                                                    {{data["labels"]["en"]["value"]}}
                                                </label>
                                                
                                            </p>
                                        </span>
                                    </div>
                                </div>

                                <h5 class="text-success mt-5 mb-4">Also known as</h5>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Aliases</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                            <!--<p *ngFor="let data of data_list[snapshot_param['id']+'-profile']['aliases'] | keyvalue"></p>-->
                                            <p *ngFor="let data of alias_language_key; let x = index">

                                                <label class="col-form-label"><b>{{commonService.map_language(data.trim())}}</b></label><br/>
                                                <span *ngFor="let lang of data_list[this.snapshot_param['id']+'-profile']['aliases'][data]; let x = index">
                                                    <label class="col-form-label">{{lang["value"]}}</label><br/>
                                                </span>
                                            
                                            </p>
                                        </span>
                                        
                                    </div>
                                </div>

                                <h5 class="text-success mt-5 mb-4">Other details</h5>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Position Held</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        

                                        <span *ngIf="data_list['P39'] !== undefined">
                                            <div *ngFor="let data of data_list['P39']; let x = index">
                                                
                                                <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                                <div class="row align-items-center" *ngIf="data['start_date'].length > 0">
                                                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-auto">
                                                        
                                                        <label class="col-form-label">
                                                            <b>Start Date</b>
                                                        </label>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngFor="let d of data['start_date']; let x = index">
                                                            <label class="col-form-label">{{d}}</label>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="row align-items-center"*ngIf="data['end_date'].length > 0">
                                                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-auto">
                                                        <label class="col-form-label">
                                                            <b>End Date</b>
                                                        </label>
                                                    </div>
                                                    <div class="col">
                                                        <span *ngFor="let d of data['end_date']; let x = index">
                                                            <label class="col-form-label">{{d}}</label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr *ngIf="data_list['P39'].length > 1"/>
                                            </div>

                                        </span>
                                        
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Comments</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                            
                                            <label class="col-form-label" *ngIf="data_list[snapshot_param['id']+'-profile']['descriptions']['en'] !== undefined">
                                                {{data_list[snapshot_param['id']+'-profile']['descriptions']['en']['value']}}
                                            </label>
                                            
                                        </span>
                                        
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Ref. No.</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                            
                                            <label class="col-form-label">{{data_list[snapshot_param['id']+'-profile']['id']}}</label>
                                            
                                        </span>
                                        
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Spouse</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <p *ngFor="let data of data_list['P26']; let x = index">
                                            <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                        </p>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Father</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <p *ngFor="let data of data_list['P22']; let x = index">
                                            <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                        </p>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Mother</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <p *ngFor="let data of data_list['P25']; let x = index">
                                            <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                        </p>
                                    </div>
                                </div>


                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Sibling</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <p *ngFor="let data of data_list['P3373']; let x = index">
                                            <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                        </p>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Child</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <p *ngFor="let data of data_list['P40']; let x = index">
                                            <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                        </p>
                                    </div>
                                </div>
                            </div><!--./wikidata-->
                            <div class="tab-pane fade" id="pills-adverse" role="tabpanel" aria-labelledby="pills-adverse-tab" tabindex="0">
                                <div class="container-fluid">
                                    <div class="row mt-5 mb-5 align-items-center">
                                        <div class="col-xxl-7 col-xl-7 col-auto">
                                            <h5>About {{commonService.toCurrency_precision(this.total_search_result,0)}} result(s)</h5>
                                        </div>
                                        <div class="col-auto">
                                            <label class="form-label">
                                                Sort By
                                            </label>
                                        </div>
                                        <div class="col-auto">
                                            <form [formGroup]="form">
                                                <select class="form-select" (change)="load_adverse()" formControlName="sort_by">
                                                    <option value="date:d">Most Recent</option>
                                                    <option value="review-rating:d">Top News</option>
                                                </select>  
                                            </form>
                                            
                                        </div>
                                    </div>
                                    
                                    <hr/>
                                    <div *ngFor="let dt of adverse_list_item; let x = index">
                                    
                                        <div class="card mb-3 border-0">
                                            <div class="row">
                                                <div class="col-auto col-form-label"><a target="_blank" href="{{dt.link}}">{{dt.title}}</a></div>
                                            </div>
                                            <div class="row g-0">
                                                <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
            
                                                    <img *ngIf="dt['pagemap'] === undefined || dt['pagemap']['cse_thumbnail'] === undefined" style="height:1in;width:1in;" src="../../assets/images/indiv-profile-pic.png" class="img-fluid" alt="...">
            
                                                    <img *ngIf="dt['pagemap'] !== undefined && dt['pagemap']['cse_thumbnail'] !== undefined" style="height:1in;width:1in;"  src="{{dt['pagemap']['cse_thumbnail'][0]['src']}}" class="img-fluid" alt="...">
                                                </div>
                                                <div class="col-xxl-10 col-xl-10 col-lg-10 col-auto">
                                                    <div class="card-body">
                                                        
                                                        <p class="card-text" [innerHTML]="dt.htmlSnippet"></p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
            
                                <div class="row container-fluid justify-content-end align-items-center mt-4 adverse">
                                    <div class="col-auto">
                                        <form [formGroup]="form" *ngIf="show_total_search_result">
            
                                            <div class="mb-3 row align-items-center">
                                                <label for="inputPassword" class="col-auto col-form-label font-14">
                                                    Rows per page
                                                </label>
                                                <div class="col">
                                                    <select style="border-style:none !important;" (change)="load_adverse()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                        <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                                    </select>
                                                </div>
                                            </div>
            
                                            
            
                                            
                                        </form>
                                    </div>
            
                                    <div class="col-auto">
                                        <div class="mb-3 row align-items-center">
                                            <div class="col-auto">
                                                {{row_counter}} - {{pagination_counter}} of {{commonService.toCurrency_precision(total_search_result,0)}}
                                            </div>
                                        </div>
                                        
                                    </div>
            
                                    <div class="col-auto">
                                        <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                            <ul class="pagination border-opacity-10">
                                                <li class="page-item " (click)="prev()" >
                                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                        <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                    </a>
                                                </li>
                                             
                                                <li class="page-item" (click)="next()">
                                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                        <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>