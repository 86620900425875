import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map, Observable } from "rxjs";



@Injectable({
    providedIn : "root"
})

export class CommonService {
    result = {};
    session_param = {};
  
    countries = {
        "DZ": {
            "country": "Algeria",
            "region": "Africa"
        },
        "AO": {
            "country": "Angola",
            "region": "Africa"
        },
        "BJ": {
            "country": "Benin",
            "region": "Africa"
        },
        "BW": {
            "country": "Botswana",
            "region": "Africa"
        },
        "BF": {
            "country": "Burkina Faso",
            "region": "Africa"
        },
        "BI": {
            "country": "Burundi",
            "region": "Africa"
        },
        "CV": {
            "country": "Cabo Verde",
            "region": "Africa"
        },
        "CM": {
            "country": "Cameroon",
            "region": "Africa"
        },
        "CF": {
            "country": "Central African Republic (the)",
            "region": "Africa"
        },
        "TD": {
            "country": "Chad",
            "region": "Africa"
        },
        "KM": {
            "country": "Comoros (the)",
            "region": "Africa"
        },
        "CD": {
            "country": "Congo (the Democratic Republic of the)",
            "region": "Africa"
        },
        "CG": {
            "country": "Congo (the)",
            "region": "Africa"
        },
        "CI": {
            "country": "Côte d'Ivoire",
            "region": "Africa"
        },
        "DJ": {
            "country": "Djibouti",
            "region": "Africa"
        },
        "EG": {
            "country": "Egypt",
            "region": "Africa"
        },
        "GQ": {
            "country": "Equatorial Guinea",
            "region": "Africa"
        },
        "ER": {
            "country": "Eritrea",
            "region": "Africa"
        },
        "SZ": {
            "country": "Eswatini",
            "region": "Africa"
        },
        "ET": {
            "country": "Ethiopia",
            "region": "Africa"
        },
        "GA": {
            "country": "Gabon",
            "region": "Africa"
        },
        "GM": {
            "country": "Gambia (the)",
            "region": "Africa"
        },
        "GH": {
            "country": "Ghana",
            "region": "Africa"
        },
        "GN": {
            "country": "Guinea",
            "region": "Africa"
        },
        "GW": {
            "country": "Guinea-Bissau",
            "region": "Africa"
        },
        "KE": {
            "country": "Kenya",
            "region": "Africa"
        },
        "LS": {
            "country": "Lesotho",
            "region": "Africa"
        },
        "LR": {
            "country": "Liberia",
            "region": "Africa"
        },
        "LY": {
            "country": "Libya",
            "region": "Africa"
        },
        "MG": {
            "country": "Madagascar",
            "region": "Africa"
        },
        "MW": {
            "country": "Malawi",
            "region": "Africa"
        },
        "ML": {
            "country": "Mali",
            "region": "Africa"
        },
        "MR": {
            "country": "Mauritania",
            "region": "Africa"
        },
        "MU": {
            "country": "Mauritius",
            "region": "Africa"
        },
        "YT": {
            "country": "Mayotte",
            "region": "Africa"
        },
        "MA": {
            "country": "Morocco",
            "region": "Africa"
        },
        "MZ": {
            "country": "Mozambique",
            "region": "Africa"
        },
        "NA": {
            "country": "Namibia",
            "region": "Africa"
        },
        "NE": {
            "country": "Niger (the)",
            "region": "Africa"
        },
        "NG": {
            "country": "Nigeria",
            "region": "Africa"
        },
        "RE": {
            "country": "Réunion",
            "region": "Africa"
        },
        "RW": {
            "country": "Rwanda",
            "region": "Africa"
        },
        "SH": {
            "country": "Saint Helena, Ascension and Tristan da Cunha",
            "region": "Africa"
        },
        "ST": {
            "country": "Sao Tome and Principe",
            "region": "Africa"
        },
        "SN": {
            "country": "Senegal",
            "region": "Africa"
        },
        "SC": {
            "country": "Seychelles",
            "region": "Africa"
        },
        "SL": {
            "country": "Sierra Leone",
            "region": "Africa"
        },
        "SO": {
            "country": "Somalia",
            "region": "Africa"
        },
        "ZA": {
            "country": "South Africa",
            "region": "Africa"
        },
        "SS": {
            "country": "South Sudan",
            "region": "Africa"
        },
        "SD": {
            "country": "Sudan (the)",
            "region": "Africa"
        },
        "TZ": {
            "country": "Tanzania, the United Republic of",
            "region": "Africa"
        },
        "TG": {
            "country": "Togo",
            "region": "Africa"
        },
        "TN": {
            "country": "Tunisia",
            "region": "Africa"
        },
        "UG": {
            "country": "Uganda",
            "region": "Africa"
        },
        "EH": {
            "country": "Western Sahara*",
            "region": "Africa"
        },
        "ZM": {
            "country": "Zambia",
            "region": "Africa"
        },
        "ZW": {
            "country": "Zimbabwe",
            "region": "Africa"
        },
        "AQ": {
            "country": "Antarctica",
            "region": "Antarctic"
        },
        "BV": {
            "country": "Bouvet Island",
            "region": "Antarctic"
        },
        "TF": {
            "country": "French Southern Territories (the)",
            "region": "Antarctic"
        },
        "HM": {
            "country": "Heard Island and McDonald Islands",
            "region": "Antarctic"
        },
        "GS": {
            "country": "South Georgia and the South Sandwich Islands",
            "region": "Antarctic"
        },
        "AF": {
            "country": "Afghanistan",
            "region": "Asia"
        },
        "AM": {
            "country": "Armenia",
            "region": "Asia"
        },
        "AZ": {
            "country": "Azerbaijan",
            "region": "Asia"
        },
        "BD": {
            "country": "Bangladesh",
            "region": "Asia"
        },
        "BT": {
            "country": "Bhutan",
            "region": "Asia"
        },
        "IO": {
            "country": "British Indian Ocean Territory (the)",
            "region": "Asia"
        },
        "BN": {
            "country": "Brunei Darussalam",
            "region": "Asia"
        },
        "KH": {
            "country": "Cambodia",
            "region": "Asia"
        },
        "CN": {
            "country": "China",
            "region": "Asia"
        },
        "GE": {
            "country": "Georgia",
            "region": "Asia"
        },
        "HK": {
            "country": "Hong Kong",
            "region": "Asia"
        },
        "IN": {
            "country": "India",
            "region": "Asia"
        },
        "ID": {
            "country": "Indonesia",
            "region": "Asia"
        },
        "JP": {
            "country": "Japan",
            "region": "Asia"
        },
        "KZ": {
            "country": "Kazakhstan",
            "region": "Asia"
        },
        "KP": {
            "country": "Korea (the Democratic People's Republic of)",
            "region": "Asia"
        },
        "KR": {
            "country": "Korea (the Republic of)",
            "region": "Asia"
        },
        "KG": {
            "country": "Kyrgyzstan",
            "region": "Asia"
        },
        "LA": {
            "country": "Lao People's Democratic Republic (the)",
            "region": "Asia"
        },
        "MO": {
            "country": "Macao",
            "region": "Asia"
        },
        "MY": {
            "country": "Malaysia",
            "region": "Asia"
        },
        "MV": {
            "country": "Maldives",
            "region": "Asia"
        },
        "MN": {
            "country": "Mongolia",
            "region": "Asia"
        },
        "MM": {
            "country": "Myanmar",
            "region": "Asia"
        },
        "NP": {
            "country": "Nepal",
            "region": "Asia"
        },
        "PK": {
            "country": "Pakistan",
            "region": "Asia"
        },
        "PH": {
            "country": "Philippines (the)",
            "region": "Asia"
        },
        "SG": {
            "country": "Singapore",
            "region": "Asia"
        },
        "LK": {
            "country": "Sri Lanka",
            "region": "Asia"
        },
        "TW": {
            "country": "Taiwan (Province of China)",
            "region": "Asia"
        },
        "TJ": {
            "country": "Tajikistan",
            "region": "Asia"
        },
        "TH": {
            "country": "Thailand",
            "region": "Asia"
        },
        "TL": {
            "country": "Timor-Leste",
            "region": "Asia"
        },
        "TM": {
            "country": "Turkmenistan",
            "region": "Asia"
        },
        "UZ": {
            "country": "Uzbekistan",
            "region": "Asia"
        },
        "VN": {
            "country": "Viet Nam",
            "region": "Asia"
        },
        "BZ": {
            "country": "Belize",
            "region": "Central America"
        },
        "CR": {
            "country": "Costa Rica",
            "region": "Central America"
        },
        "SV": {
            "country": "El Salvador",
            "region": "Central America"
        },
        "GT": {
            "country": "Guatemala",
            "region": "Central America"
        },
        "HN": {
            "country": "Honduras",
            "region": "Central America"
        },
        "MX": {
            "country": "Mexico",
            "region": "Central America"
        },
        "NI": {
            "country": "Nicaragua",
            "region": "Central America"
        },
        "PA": {
            "country": "Panama",
            "region": "Central America"
        },
        "AX": {
            "country": "Åland Islands",
            "region": "Europe"
        },
        "AL": {
            "country": "Albania",
            "region": "Europe"
        },
        "AD": {
            "country": "Andorra",
            "region": "Europe"
        },
        "AT": {
            "country": "Austria",
            "region": "Europe"
        },
        "BY": {
            "country": "Belarus",
            "region": "Europe"
        },
        "BE": {
            "country": "Belgium",
            "region": "Europe"
        },
        "BA": {
            "country": "Bosnia and Herzegovina",
            "region": "Europe"
        },
        "BG": {
            "country": "Bulgaria",
            "region": "Europe"
        },
        "HR": {
            "country": "Croatia",
            "region": "Europe"
        },
        "CY": {
            "country": "Cyprus",
            "region": "Europe"
        },
        "CZ": {
            "country": "Czechia",
            "region": "Europe"
        },
        "DK": {
            "country": "Denmark",
            "region": "Europe"
        },
        "EE": {
            "country": "Estonia",
            "region": "Europe"
        },
        "FO": {
            "country": "Faroe Islands (the)",
            "region": "Europe"
        },
        "FI": {
            "country": "Finland",
            "region": "Europe"
        },
        "FR": {
            "country": "France",
            "region": "Europe"
        },
        "DE": {
            "country": "Germany",
            "region": "Europe"
        },
        "GI": {
            "country": "Gibraltar",
            "region": "Europe"
        },
        "GR": {
            "country": "Greece",
            "region": "Europe"
        },
        "GG": {
            "country": "Guernsey",
            "region": "Europe"
        },
        "VA": {
            "country": "Holy See (the)",
            "region": "Europe"
        },
        "HU": {
            "country": "Hungary",
            "region": "Europe"
        },
        "IS": {
            "country": "Iceland",
            "region": "Europe"
        },
        "IE": {
            "country": "Ireland",
            "region": "Europe"
        },
        "IM": {
            "country": "Isle of Man",
            "region": "Europe"
        },
        "IT": {
            "country": "Italy",
            "region": "Europe"
        },
        "JE": {
            "country": "Jersey",
            "region": "Europe"
        },
        "LV": {
            "country": "Latvia",
            "region": "Europe"
        },
        "LI": {
            "country": "Liechtenstein",
            "region": "Europe"
        },
        "LT": {
            "country": "Lithuania",
            "region": "Europe"
        },
        "LU": {
            "country": "Luxembourg",
            "region": "Europe"
        },
        "MT": {
            "country": "Malta",
            "region": "Europe"
        },
        "MD": {
            "country": "Moldova (the Republic of)",
            "region": "Europe"
        },
        "MC": {
            "country": "Monaco",
            "region": "Europe"
        },
        "ME": {
            "country": "Montenegro",
            "region": "Europe"
        },
        "NL": {
            "country": "Netherlands (the)",
            "region": "Europe"
        },
        "MK": {
            "country": "North Macedonia",
            "region": "Europe"
        },
        "NO": {
            "country": "Norway",
            "region": "Europe"
        },
        "PL": {
            "country": "Poland",
            "region": "Europe"
        },
        "PT": {
            "country": "Portugal",
            "region": "Europe"
        },
        "RO": {
            "country": "Romania",
            "region": "Europe"
        },
        "RU": {
            "country": "Russian Federation (the)",
            "region": "Europe"
        },
        "SM": {
            "country": "San Marino",
            "region": "Europe"
        },
        "RS": {
            "country": "Serbia",
            "region": "Europe"
        },
        "SK": {
            "country": "Slovakia",
            "region": "Europe"
        },
        "SI": {
            "country": "Slovenia",
            "region": "Europe"
        },
        "ES": {
            "country": "Spain",
            "region": "Europe"
        },
        "SJ": {
            "country": "Svalbard and Jan Mayen",
            "region": "Europe"
        },
        "SE": {
            "country": "Sweden",
            "region": "Europe"
        },
        "CH": {
            "country": "Switzerland",
            "region": "Europe"
        },
        "TR": {
            "country": "Turkey",
            "region": "Europe"
        },
        "UA": {
            "country": "Ukraine",
            "region": "Europe"
        },
        "GB": {
            "country": "United Kingdom of Great Britain and Northern Ireland (the)",
            "region": "Europe"
        },
        "BH": {
            "country": "Bahrain",
            "region": "Middle East"
        },
        "IR": {
            "country": "Iran (Islamic Republic of)",
            "region": "Middle East"
        },
        "IQ": {
            "country": "Iraq",
            "region": "Middle East"
        },
        "IL": {
            "country": "Israel",
            "region": "Middle East"
        },
        "JO": {
            "country": "Jordan",
            "region": "Middle East"
        },
        "KW": {
            "country": "Kuwait",
            "region": "Middle East"
        },
        "LB": {
            "country": "Lebanon",
            "region": "Middle East"
        },
        "OM": {
            "country": "Oman",
            "region": "Middle East"
        },
        "PS": {
            "country": "Palestine, State of",
            "region": "Middle East"
        },
        "QA": {
            "country": "Qatar",
            "region": "Middle East"
        },
        "SA": {
            "country": "Saudi Arabia",
            "region": "Middle East"
        },
        "SY": {
            "country": "Syrian Arab Republic (the)",
            "region": "Middle East"
        },
        "AE": {
            "country": "United Arab Emirates (the)",
            "region": "Middle East"
        },
        "YE": {
            "country": "Yemen",
            "region": "Middle East"
        },
        "BM": {
            "country": "Bermuda",
            "region": "North America"
        },
        "CA": {
            "country": "Canada",
            "region": "North America"
        },
        "GL": {
            "country": "Greenland",
            "region": "North America"
        },
        "PM": {
            "country": "Saint Pierre and Miquelon",
            "region": "North America"
        },
        "US": {
            "country": "United States of America (the)",
            "region": "North America"
        },
        "AS": {
            "country": "American Samoa",
            "region": "Oceania"
        },
        "AU": {
            "country": "Australia",
            "region": "Oceania"
        },
        "CX": {
            "country": "Christmas Island",
            "region": "Oceania"
        },
        "CC": {
            "country": "Cocos (Keeling) Islands (the)",
            "region": "Oceania"
        },
        "CK": {
            "country": "Cook Islands (the)",
            "region": "Oceania"
        },
        "FJ": {
            "country": "Fiji",
            "region": "Oceania"
        },
        "PF": {
            "country": "French Polynesia",
            "region": "Oceania"
        },
        "GU": {
            "country": "Guam",
            "region": "Oceania"
        },
        "KI": {
            "country": "Kiribati",
            "region": "Oceania"
        },
        "MH": {
            "country": "Marshall Islands (the)",
            "region": "Oceania"
        },
        "FM": {
            "country": "Micronesia (Federated States of)",
            "region": "Oceania"
        },
        "NR": {
            "country": "Nauru",
            "region": "Oceania"
        },
        "NC": {
            "country": "New Caledonia",
            "region": "Oceania"
        },
        "NZ": {
            "country": "New Zealand",
            "region": "Oceania"
        },
        "NU": {
            "country": "Niue",
            "region": "Oceania"
        },
        "NF": {
            "country": "Norfolk Island",
            "region": "Oceania"
        },
        "MP": {
            "country": "Northern Mariana Islands (the)",
            "region": "Oceania"
        },
        "PW": {
            "country": "Palau",
            "region": "Oceania"
        },
        "PG": {
            "country": "Papua New Guinea",
            "region": "Oceania"
        },
        "PN": {
            "country": "Pitcairn",
            "region": "Oceania"
        },
        "WS": {
            "country": "Samoa",
            "region": "Oceania"
        },
        "SB": {
            "country": "Solomon Islands",
            "region": "Oceania"
        },
        "TK": {
            "country": "Tokelau",
            "region": "Oceania"
        },
        "TO": {
            "country": "Tonga",
            "region": "Oceania"
        },
        "TV": {
            "country": "Tuvalu",
            "region": "Oceania"
        },
        "UM": {
            "country": "United States Minor Outlying Islands (the)",
            "region": "Oceania"
        },
        "VU": {
            "country": "Vanuatu",
            "region": "Oceania"
        },
        "WF": {
            "country": "Wallis and Futuna",
            "region": "Oceania"
        },
        "AR": {
            "country": "Argentina",
            "region": "South America"
        },
        "BO": {
            "country": "Bolivia (Plurinational State of)",
            "region": "South America"
        },
        "BR": {
            "country": "Brazil",
            "region": "South America"
        },
        "CL": {
            "country": "Chile",
            "region": "South America"
        },
        "CO": {
            "country": "Colombia",
            "region": "South America"
        },
        "EC": {
            "country": "Ecuador",
            "region": "South America"
        },
        "FK": {
            "country": "Falkland Islands (the) [Malvinas]",
            "region": "South America"
        },
        "GF": {
            "country": "French Guiana",
            "region": "South America"
        },
        "GY": {
            "country": "Guyana",
            "region": "South America"
        },
        "PY": {
            "country": "Paraguay",
            "region": "South America"
        },
        "PE": {
            "country": "Peru",
            "region": "South America"
        },
        "SR": {
            "country": "Suriname",
            "region": "South America"
        },
        "UY": {
            "country": "Uruguay",
            "region": "South America"
        },
        "VE": {
            "country": "Venezuela (Bolivarian Republic of)",
            "region": "South America"
        },
        "AI": {
            "country": "Anguilla",
            "region": "The Caribbean"
        },
        "AG": {
            "country": "Antigua and Barbuda",
            "region": "The Caribbean"
        },
        "AW": {
            "country": "Aruba",
            "region": "The Caribbean"
        },
        "BS": {
            "country": "Bahamas (the)",
            "region": "The Caribbean"
        },
        "BB": {
            "country": "Barbados",
            "region": "The Caribbean"
        },
        "BQ": {
            "country": "Bonaire, Sint Eustatius and Saba",
            "region": "The Caribbean"
        },
        "KY": {
            "country": "Cayman Islands (the)",
            "region": "The Caribbean"
        },
        "CU": {
            "country": "Cuba",
            "region": "The Caribbean"
        },
        "CW": {
            "country": "Curaçao",
            "region": "The Caribbean"
        },
        "DM": {
            "country": "Dominica",
            "region": "The Caribbean"
        },
        "DO": {
            "country": "Dominican Republic (the)",
            "region": "The Caribbean"
        },
        "GD": {
            "country": "Grenada",
            "region": "The Caribbean"
        },
        "GP": {
            "country": "Guadeloupe",
            "region": "The Caribbean"
        },
        "HT": {
            "country": "Haiti",
            "region": "The Caribbean"
        },
        "JM": {
            "country": "Jamaica",
            "region": "The Caribbean"
        },
        "MQ": {
            "country": "Martinique",
            "region": "The Caribbean"
        },
        "MS": {
            "country": "Montserrat",
            "region": "The Caribbean"
        },
        "PR": {
            "country": "Puerto Rico",
            "region": "The Caribbean"
        },
        "BL": {
            "country": "Saint Barthélemy",
            "region": "The Caribbean"
        },
        "KN": {
            "country": "Saint Kitts and Nevis",
            "region": "The Caribbean"
        },
        "LC": {
            "country": "Saint Lucia",
            "region": "The Caribbean"
        },
        "MF": {
            "country": "Saint Martin (French part)",
            "region": "The Caribbean"
        },
        "VC": {
            "country": "Saint Vincent and the Grenadines",
            "region": "The Caribbean"
        },
        "SX": {
            "country": "Sint Maarten (Dutch part)",
            "region": "The Caribbean"
        },
        "TT": {
            "country": "Trinidad and Tobago",
            "region": "The Caribbean"
        },
        "TC": {
            "country": "Turks and Caicos Islands (the)",
            "region": "The Caribbean"
        },
        "VG": {
            "country": "Virgin Islands (British)",
            "region": "The Caribbean"
        },
        "VI": {
            "country": "Virgin Islands (U.S.)",
            "region": "The Caribbean"
        }
    };
    

    constructor(private router: Router,
        private http: HttpClient){
            
    }

    map_countries(code){
        if(this.countries[code] !== undefined){
            return this.countries[code]["country"];
        }else{
            return "";
        }
        
    }

    ucwords (str) {
        str = str.toLowerCase();
        return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
            return $1.toUpperCase();
        });
    }//end

    catchIsNaN(Amt,precision : number = 2){
        return !isFinite(parseFloat(Amt))? 0 : parseFloat(parseFloat(Amt).toFixed(precision));
    }//end

    catchNull(str){
        str = str == null? '' : str;
        return str; 
    }//end

    addDays(date,days){
        date = new Date(date);
        date.setDate(date.getDate() + days);
        return date;
    }

    toCurrency_precision(amount : number,precision : number = 2) {
        var amt = "";
        amt = amount.toFixed(precision);
        if (!amt) {
            amt = "0.00"; 
        }//end if
        
        amt += '';
        const amt_arr = amt.split(".");
        //return value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        
        return amt_arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (amt_arr[1] ? "." + amt_arr[1] : "");
    }

    
    randomString(length) {
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    formatDate(str){
        const date_str = str.substring(0,8);
        const time_str = str.substring(8);

        const year              = date_str.substring(0,4);
        const month             = date_str.substring(4,(4+2));
        const day               = date_str.substring(6);

        const hour              = time_str.substring(0,2);
        const min               = time_str.substring(2,(2+2));
        const sec               = time_str.substring(4);
        const final_date_str    = month+'/'+day+'/'+year + ' ' + hour + ':' + min + ':' + sec;
        const final_date        = new Date(final_date_str);
        return final_date

    }//end if


    escape_http_char(val : any){
        const special_char = [
            '<',
            '>',
            '#',
            '%',
            '+',
            '{',
            '}',
            '|',
            '\\',
            '^',
            '~',
            '[',
            ']',
            '‘',
            ';',
            '/',
            '?',
            ':',
            '=',
            '&'
        ];

        const replacement_char = [
            '%3C',
            '%3E',
            '%23',
            '%25',
            '%2B',
            '%7B',
            '%7D',
            '%7C',
            '%5C',
            '%5E',
            '%7E',
            '%5B',
            '%5D',
            '%60',
            '%3B',
            '%2F',
            '%3F',
            '%3A',
            '%3D',
            '%26',
            '%24'
        ];

    
        for(const k in special_char){
            if(val.indexOf(special_char[k]) >= 0){
                //contains special char
                val = val.replace(special_char[k],replacement_char[k]);
            }//end if
        }//end for

        return val;

        
    }

    

    removeDuplicates(arr) { 
        return arr.filter((item, 
            index) => arr.indexOf(item) === index); 
    }


    map_language(param){
        var languages = {
            "ar" : "Arabic",
            "arz" : "Egyptian Arabic",
            "be-tarask" : "Taraškievica",
            "bej" : "Beja",
            "bfi" : "British Sign Language",
            "bfq" : "Badaga",
            "bg" : "Bulgarian",
            "bgn" : "Western Balochi",
            "bh" : "Bhojpuri",
            "bi" : "Bislama",
            "bjn" : "Banjar",
            "bla" : "Blackfoot",
            "blk" : "Pa'O",
            "bm" : "Bambara",
            "bn" : "Bangla",
            "bnn" : "Bunun",
            "bnt-mpi" : "Mpiin",
            "bo" : "Tibetan",
            "bpy" : "Bishnupriya Manipuri",
            "bqi" : "Bakhtiari",
            "br" : "Breton",
            "brh" : "Brahui",
            "brx" : "Bodo",
            "bs" : "Bosnian",
            "bsa" : "Abinomn",
            "bsk" : "Burushaski",
            "bss" : "Kose",
            "btm" : "Mandailing Batak",
            "bto" : "Rinconada Bikol",
            "bug" : "Bugis",
            "bua" : "Buryat",
            "bxr" : "Russia Buriat",
            "byq" : "Basay",
            "bzg" : "Babuza",
            "bzs" : "Brazilian Sign Language",
            "ca" : "Catalan",
            "ca-valencia" : "Valencian",
            "cak" : "Kaqchikel",
            "cal" : "Carolinian",
            "cbk-x-zam" : "Zamboanga Chavacano",
            "cbk-zam" : "Chavacano",
            "ccp" : "Chakma",
            "ccp-beng" : "Chakma in Bengali script",
            "cdo" : "Eastern Min",
            "ce" : "Chechen",
            "ceb" : "Cebuano",
            "cgc" : "Kagayanen",
            "ch" : "Chamorro",
            "chn" : "Chinook Jargon",
            "cho" : "Choctaw",
            "chr" : "Cherokee",
            "chu" : "Glagolitic",
            "chy" : "Cheyenne",
            "ckb" : "Sorani",
            "ckt" : "Chukchi",
            "ckv" : "Kavalan",
            "clc" : "Chilcotin",
            "cnh" : "Hakha–Chin",
            "cnr" : "Montenegrin",
            "co" : "Corsican",
            "cop" : "Coptic",
            "cps" : "Capisnon",
            "cpx" : "Pu-Xian Min",
            "cr" : "Cree",
            "crh" : "Crimean Tatar",
            "crh-cyrl" : "Crimean Tatar in Cyrillic script",
            "crh-latn" : "Crimean Tatar in Latin script",
            "crl" : "Northern East Cree",
            "crs" : "Seychellois Creole",
            "cs" : "Czech",
            "csb" : "Kashubian",
            "ctg" : "Chittagonian",
            "cu": "cu – church slavic",
            "cu-num" : "Slavonic numerals",
            "cu-nun" : "Slavonic musical notation",
            "cv" : "Chuvash",
            "cy" : "Welsh",
            "da" : "Danish",
            "dag" : "Dagbani",
            "dar" : "Dargwa",
            "de" : "German",
            "de-at" : "Austrian German",
            "de-be" : "Belgian German",
            "de-ch" : "Swiss High German",
            "de-formal" : "formal address in German",
            "din" : "Dinka",
            "diq" : "Zazaki",
            "dlm" : "Dalmatian",
            "dru" : "Rukai",
            "dsb" : "Lower Sorbian",
            "dtp" : "Kadazandusun",
            "dty" : "Doteli",
            "dua" : "Duala",
            "dv" : "Maldivian",
            "dz" : "Dzongkha",
            "ee" : "Ewe",
            "efi" : "Efik",
            "egl" : "Emilian",
            "el" : "Greek",
            "el-cy" : "Cypriot Greek",
            "eml" : "Emilian–Romagnol",
            "en" : "English",
            "en-au" : "Australian English",
            "en-ca" : "Canadian English",
            "en-gb" : "British English",
            "en-in" : "Indian English",
            "en-simple" : "Simple English",
            "en-us" : "American English",
            "en-us-so" : "Southern American English",
            "en-us-tx" : "Texan English",
            "enm" : "Middle English",
            "eo" : "Esperanto",
            "eo-hsistemo" : "System H",
            "eo-xsistemo" : "System X",
            "es" : "Spanish",
            "es-419" : "Latin American Spanish",
            "es-formal" : "Spanish (formal address)",
            "esu" : "Central Alaskan Yup'ik",
            "esx-esk-pro" : "Proto-Inuit-Yupik",
            "et" : "Estonian",
            "ett" : "Etruscan",
            "eu" : "Basque",
            "ext" : "Extremaduran",
            "eya" : "Eyak",
            "fa" : "Persian",
            "fa-af" : "Dari",
            "ff" : "Fula",
            "fi" : "Finnish",
            "fit" : "Meänkieli",
            "fiu-vro" : "Võro",
            "fj" : "Fijian",
            "fkv" : "Kven",
            "fo" : "Faroese",
            "fon" : "Fon",
            "fos" : "Siraya",
            "fr" : "French",
            "fr-be" : "Belgian French",
            "fr-ca" : "Canadian French",
            "fr-x-nrm" : "Norman",
            "frc" : "Louisiana French",
            "frm" : "Middle French",
            "fro" : "Old French",
            "frp" : "Franco-Provençal",
            "frr" : "North Frisian",
            "fuf" : "Pular",
            "fur" : "Friulian",
            "fy" : "West Frisian",
            "ga" : "Irish",
            "gaa" : "Ga",
            "gag" : "Gagauz",
            "gan" : "Gan Chinese",
            "gan-hans" : "simplified Gan",
            "gan-hant" : "traditional Gan",
            "gcf" : "Guadeloupean Creole",
            "gcr" : "Guianan Creole",
            "gd" : "Scottish Gaelic",
            "gez" : "Ge'ez",
            "gil" : "Gilbertese",
            "gl" : "Galician",
            "glk" : "Gilaki",
            "gmh" : "Middle High German",
            "gml" : "Middle Low German",
            "gn" : "Guarani",
            "goh" : "Old High German",
            "gom" : "Goan Konkani",
            "gom-deva" : "Goan Konkani in Devanagari script",
            "gom-latn" : "Goan Konkani in Latin script",
            "gor" : "Gorontalo",
            "got" : "Gothic",
            "gpe" : "Ghanaian Pidgin English",
            "grc" : "Ancient Greek",
            "gsg" : "German Sign Language",
            "gsw-fr" : "Alsatian",
            "gu" : "Gujarati",
            "guc" : "Wayuu",
            "guw" : "Gun",
            "gv" : "Manx",
            "ha" : "Hausa",
            "hai" : "Haida",
            "hak" : "Hakka Chinese",
            "haw" : "Hawaiian",
            "hbo" : "Biblical Hebrew",
            "he" : "Hebrew",
            "hi" : "Hindi",
            "hif" : "Fiji Hindi",
            "hif-latn" : "Fiji Hindi in Latin script",
            "hil" : "Hiligaynon",
            "hit-latn" : "Hittite in Latin Script",
            "hno" : "Hindko",
            "ho" : "Hiri Motu",
            "hoc" : "Ho",
            "hr" : "Croatian",
            "hrx" : "Hunsrik",
            "hsb" : "Upper Sorbian",
            "ht" : "Haitian Creole",
            "hu" : "Hungarian",
            "hu-formal" : "formal Hungarian",
            "hy" : "Armenian",
            "hyw" : "Western Armenian",
            "hz" : "Herero",
            "ia" : "Interlingua",
            "id" : "Indonesian",
            "ie" : "Interlingue",
            "ig" : "Igbo",
            "ii" : "Nuosu",
            "ik" : "Iñupiaq",
            "ike-cans" : "Eastern Canadian Inuktut in Aboriginal syllabics",
            "ike-latn" : "Eastern Canadian Inuktut in Latin script",
            "ilo" : "Ilocano",
            "inc-ohi" : "Old Hindi",
            "inc-opa" : "Old Punjabi",
            "ine-pro" : "Proto-Indo-European",
            "inh" : "Ingush",
            "io" : "Ido",
            "is" : "Icelandic",
            "it" : "Italian",
            "it-x-tara" : "Tarantino",
            "iu" : "Inuktitut",
            "ja" : "Japanese",
            "ja-hani" : "Japanese written in kanji",
            "ja-hira" : "Japanese written in hiragana",
            "ja-hrkt" : "Japanese written in kana",
            "ja-kana" : "Japanese written in katakana",
            "jam" : "Jamaican Patois",
            "jax" : "Jambi Malay",
            "jbo" : "Lojban",
            "jut" : "Jutlandic",
            "jv" : "Javanese",
            "jv-x-bms" : "Banyumasan Javanese",
            "ka" : "Georgian",
            "kaa" : "Karakalpak",
            "kab" : "Kabyle",
            "kae" : "Ketagalan",
            "kbd" : "Kabardian",
            "kbd-cyrl" : "Kabardian in Cyrillic script",
            "kbg" : "Khamba",
            "kbp" : "Kabiye",
            "kcg" : "Tyap",
            "kea" : "Cape Verdean Creole",
            "kg" : "Kongo",
            "kgp" : "Kaingang",
            "kha" : "Khasi",
            "khg" : "Khams Tibetan",
            "khw" : "Khowar",
            "ki" : "Gikuyu",
            "kiu" : "Kirmanjki",
            "kj" : "Kwanyama",
            "kjg" : "Khmu",
            "kjh" : "Khakas",
            "kjp" : "Eastern Pwo",
            "kk" : "Kazakh",
            "kk-arab" : "Kazakh in Arabic script",
            "kk-cn" : "Kazakh in China",
            "kk-cyrl" : "Kazakh in Cyrillic script",
            "kk-kz" : "Kazakh in Kazakhstan",
            "kk-latn" : "Kazakh in Latin script",
            "kk-tr" : "Kazakh in Turkey",
            "kl" : "Greenlandic",
            "km" : "Khmer",
            "kmz" : "Khorasani Turkic",
            "kn" : "Kannada",
            "ko" : "Korean",
            "ko-kp" : "North Korean standard language",
            "ko-kr" : "South Korean standard language",
            "koi" : "Permyak",
            "koy" : "Koyukon",
            "kr" : "Kanuri",
            "krc" : "Karachay-Balkar",
            "kri" : "Krio",
            "krj" : "Karay-a",
            "krl" : "Karelian",
            "krx" : "Karon",
            "ks" : "Kashmiri",
            "ks-arab" : "Kashmiri in Arabic script",
            "ks-deva" : "Kashmiri in Devanagari script",
            "ksh": "ksh – colognian",
            "ksw" : "S'gaw Karen",
            "ku" : "Kurdish",
            "ku-arab" : "Kurdish in Arabic script",
            "ku-latn" : "Kurdish in Latin script",
            "kum" : "Kumyk",
            "kut" : "Kutenai",
            "kv" : "Komi",
            "kvr" : "Kerinci",
            "kw" : "Cornish",
            "ky" : "Kyrgyz",
            "ky-arab" : "Kyrgyz in Arabic script",
            "ky-cyrl" : "Kyrgyz in Cyrillic script",
            "la" : "Latin",
            "lad" : "Judaeo-Spanish",
            "lag" : "Rangi",
            "lb" : "Luxembourgish",
            "lbe" : "Lak",
            "lex" : "Luang",
            "lez" : "Lezgian",
            "lfn" : "Lingua Franca Nova",
            "lg" : "Luganda",
            "li" : "Limburgish",
            "lij" : "Ligurian",
            "lij-mc" : "Monégasque",
            "liv" : "Livonian",
            "lki" : "Laki",
            "lkt" : "Lakota",
            "lld" : "Ladin",
            "lmo" : "Lombard",
            "ln" : "Lingala",
            "lo" : "Lao",
            "loz" : "Lozi",
            "lrc" : "Northern Luri",
            "lt" : "Lithuanian",
            "ltg" : "Latgalian",
            "lua" : "Luba-Kasai",
            "lus" : "Mizo",
            "luz" : "Southern Luri",
            "lv" : "Latvian",
            "lvk" : "Lavukaleve",
            "lzh" : "Classical Chinese",
            "lzz" : "Laz",
            "mad" : "Madurese",
            "mai" : "Maithili",
            "mdf" : "Moksha",
            "mfe" : "Mauritian Creole",
            "mg" : "Malagasy",
            "mh" : "Marshallese",
            "mhr" : "Meadow Mari",
            "mi" : "Māori",
            "mic" : "Mi'kmaq",
            "min" : "Minangkabau",
            "mis" : "language without a specific language code",
            "mis-x-Q2417210" : "Old Swedish",
            "mis-x-Q8047534" : "Yalálag Zapotec",
            "mk" : "Macedonian",
            "ml" : "Malayalam",
            "mn" : "Mongolian",
            "mnc" : "Manchu",
            "mni" : "Meitei",
            "mnw-pi" : "Mon-Pali",
            "mo" : "Moldovan",
            "moe" : "Innu-aimun",
            "mr" : "Marathi",
            "mrh" : "Mara",
            "mrj" : "Hill Mari",
            "ms" : "Malay",
            "ms-arab" : "Malay in Jawi script",
            "ms-my" : "Malaysian Malay",
            "mt" : "Maltese",
            "mui" : "Musi",
            "mul" : "multiple languages",
            "mus" : "Muscogee",
            "mwl" : "Mirandese",
            "mwv" : "Mentawai",
            "my" : "Burmese",
            "myv" : "Erzya",
            "mzn" : "Mazanderani",
            "na" : "Nauruan",
            "nah" : "Nahuatl",
            "nan" : "Southern Min",
            "nap" : "Neapolitan",
            "nb" : "Bokmål",
            "nds" : "Low German",
            "nds-nl" : "Dutch Low Saxon",
            "ne" : "Nepali",
            "new" : "Newar",
            "ng" : "Ndonga",
            "nia" : "Nias",
            "niu" : "Niuean",
            "nl" : "Dutch",
            "nl-be" : "Belgian Dutch",
            "nn" : "Nynorsk",
            "nn-hognorsk" : "Høgnorsk",
            "no" : "Norwegian",
            "nod" : "Northern Thai",
            "nog" : "Nogai",
            "non" : "Old Norse",
            "nov" : "Novial",
            "nqo" : "NKo",
            "nr" : "Southern Ndebele",
            "nrf-gg" : "Guernésiais",
            "nrf-je" : "Jèrriais",
            "nsk" : "Naskapi",
            "nso" : "Northern Sotho",
            "nui" : "Kombe",
            "nv" : "Navajo",
            "nxm" : "Numidian",
            "ny" : "Chewa",
            "nys" : "Noongar",
            "oc" : "Occitan",
            "oj" : "Ojibwe",
            "olo" : "Livvi-Karelian",
            "om" : "Oromo",
            "ood" : "O'odham",
            "or" : "Odia",
            "orv" : "Rus' Law",
            "os" : "Ossetian",
            "osa" : "Osage",
            "osa-latn" : "Osage in Latin script",
            "ota" : "Ottoman Turkish",
            "otk" : "Old Turkic",
            "ovd" : "Elfdalian",
            "pa" : "Gurmukhi",
            "pag" : "Pangasinan",
            "pam" : "Kapampangan",
            "pap" : "Papiamento",
            "pcd" : "Picard",
            "pdc" : "Pennsylvania German",
            "pdt" : "Plautdietsch",
            "peo" : "Old Persian",
            "pfl" : "Palatinate German",
            "pi" : "Pali",
            "pih" : "Pitkern",
            "pis" : "Pijin",
            "pjt" : "Pitjantjatjara",
            "pko" : "Pökoot",
            "pks" : "Pakistan Sign Language",
            "pl" : "Polish",
            "pms" : "Piedmontese",
            "pmy" : "Papuan Malay",
            "pnb" : "Shahmukhi",
            "pnt" : "Pontic Greek",
            "pox" : "Polabian",
            "ppu" : "Papora-Hoanya",
            "prg" : "Old Prussian",
            "ps" : "Pashto",
            "pt" : "Portuguese",
            "pt-ao1990" : "Portuguese Language Orthographic Agreement of 1990",
            "pt-br" : "Brazilian Portuguese",
            "pwn" : "Paiwan",
            "pyu" : "Puyuma",
            "qu" : "Quechua",
            "quc" : "K’iche’",
            "qug" : "Quichua",
            "qwe-pro" : "Proto-Quechua",
            "qya" : "Quenya",
            "rar" : "Cook Islands Maori",
            "rcf" : "Réunion Creole",
            "rgn" : "Romagnol",
            "rif" : "Tarifit",
            "rki" : "Rakhine",
            "rkt" : "Rangpuri",
            "rm" : "Romansh",
            "rm-puter" : "Putèr",
            "rm-rumgr" : "Rumantsch Grischun",
            "rm-surmiran" : "Surmiran",
            "rm-sursilv" : "Sursilvan",
            "rm-sutsilv" : "Sutsilvan",
            "rm-vallader" : "Vallader",
            "rmc" : "Carpathian Romani",
            "rmf" : "Finnish Kalo",
            "rmy" : "Romani",
            "rn" : "Kirundi",
            "ro" : "Romanian",
            "ru" : "Russian",
            "ru-sib" : "Siberian",
            "rue" : "Rusyn",
            "rup" : "Aromanian",
            "ruq" : "Megleno-Romanian",
            "ruq-cyrl" : "Megleno-Romanian in Cyrillic script",
            "ruq-latn" : "Megleno-Romanian in Latin script",
            "rw" : "Kinyarwanda",
            "rwr" : "Marwari",
            "ryu" : "Okinawan",
            "sa" : "Sanskrit",
            "sah" : "Yakut",
            "sai-par" : "Paratio",
            "sat" : "Santali",
            "sat-beng" : "Santali in Bengali script",
            "sc" : "Sardinian",
            "scn" : "Sicilian",
            "sco" : "Scots",
            "sd" : "Sindhi",
            "sdc" : "Sassarese",
            "sdh" : "Southern Kurdish",
            "se" : "Northern Sami",
            "sei" : "Seri",
            "ses" : "Koyraboro Senni",
            "sg" : "Sango",
            "sh" : "Serbo-Croatian",
            "shi" : "Tachelhit",
            "shi-latn" : "Tachelhit in Latin script",
            "shi-tfng" : "Tachelhit in Tifinagh script",
            "shn" : "Shan",
            "shy" : "Shawiya",
            "shy-latn" : "Shawiya in Latin script",
            "si" : "Sinhala",
            "sid" : "Sidamo",
            "sjd" : "Kildin Sami",
            "sje" : "Pite Sami",
            "sjm" : "Mapun",
            "sjn" : "Sindarin",
            "sjt" : "Ter Sami",
            "sju" : "Ume Sami",
            "sk" : "Slovak",
            "skr" : "Saraiki",
            "sl" : "Slovene",
            "sli" : "Silesian German",
            "sm" : "Samoan",
            "sma" : "Southern Sami",
            "smj" : "Lule Sami",
            "smn" : "Inari Sami",
            "sms" : "Skolt Sami",
            "sn" : "Shona",
            "so" : "Somali",
            "sou" : "Southern Thai",
            "sq" : "Albanian",
            "sr" : "Serbian",
            "sr-ec" : "Serbian in Cyrilic script",
            "sr-el" : "Serbian in Latin script",
            "srn" : "Sranan Tongo",
            "srq" : "Sirionó",
            "ss" : "Swazi",
            "ssf" : "Thao",
            "st" : "Sesotho",
            "sth" : "Shelta",
            "stq" : "Saterland Frisian",
            "sty" : "Siberian Tatar",
            "su" : "Sundanese",
            "sux-latn" : "Sumerian in Latin script",
            "sv" : "Swedish",
            "sw" : "Swahili",
            "sxr" : "Saaroa",
            "syc" : "Syriac",
            "syl" : "Sylheti",
            "syl-beng" : "Sylheti in Bengali-Assamese script",
            "szl" : "Silesian",
            "szy" : "Sakizaya",
            "ta" : "Tamil",
            "tay" : "Atayal",
            "tce" : "Southern Tutchone",
            "tcy" : "Tulu",
            "te" : "Telugu",
            "tek" : "East Teke",
            "tet" : "Tetum",
            "tg" : "Tajik",
            "tg-cyrl" : "Tajik in Cyrillic script",
            "tg-latn" : "Tajik in Latin script",
            "th" : "Thai",
            "ti" : "Tigrinya",
            "tk" : "Turkmen",
            "tl" : "Tagalog",
            "tlh" : "Klingon",
            "tlh-latn" : "Klingon in Okrand notation",
            "tlh-piqd" : "Klingon in pIqaD",
            "tli" : "Tlingit",
            "tly" : "Talysh",
            "tly-cyrl" : "Talysh in Cyrillic script",
            "tn" : "Tswana",
            "to" : "Tongan",
            "tok" : "Toki Pona",
            "tpi" : "Tok Pisin",
            "tr" : "Turkish",
            "tru" : "Turoyo",
            "trv" : "Seediq",
            "ts" : "Tsonga",
            "tsg" : "Tausug",
            "tsk" : "Tseku",
            "tsu" : "Tsou",
            "tt" : "Tatar",
            "tt-cyrl" : "Tatar in Cyrillic script",
            "tt-latn" : "Tatar in Latin script",
            "ttm" : "Northern Tutchone",
            "tu" : "Tunisian Arabic",
            "tum" : "Tumbuka",
            "tup" : "Tupi",
            "tvl" : "Tuvaluan",
            "tvn" : "Tavoyan",
            "tvx" : "Taivoan",
            "tw" : "Twi",
            "ty" : "Tahitian",
            "tyv" : "Tuvan",
            "tzl" : "Talossan",
            "tzm" : "Central Atlas Tamazight",
            "udm" : "Udmurt",
            "ug" : "Uyghur",
            "ug-arab" : "Uyghur in Arabic script",
            "ug-latn" : "Uyghur in Latin script",
            "uk" : "Ukrainian",
            "umu" : "Munsee",
            "und" : "undetermined language",
            "ur" : "Urdu",
            "urh" : "Urhobo",
            "urj-koo" : "Old Komi",
            "uun" : "Pazeh-Kaxabu",
            "uz" : "Uzbek",
            "uz-cyrl" : "Uzbek in Cyrillic script",
            "uz-latn" : "Uzbek in Latin script",
            "uzs" : "Southern Uzbek",
            "ve" : "Venda",
            "vec" : "Venetian",
            "vep" : "Veps",
            "vi" : "Vietnamese",
            "vls" : "West Flemish",
            "vmf" : "Main-Franconian",
            "vo" : "Volapük",
            "vot" : "Votic",
            "wa" : "Walloon",
            "wal" : "Wolayttattuwa",
            "war" : "Waray",
            "wbl" : "Wakhi",
            "wen" : "Sorbian",
            "wls" : "Wallisian",
            "wo" : "Wolof",
            "wuu" : "Wu Chinese",
            "wya" : "Wendat",
            "wym" : "Wymysorys",
            "xal" : "Kalmyk",
            "xme-ttc-pro" : "Proto-Tatic",
            "xmf" : "Mingrelian",
            "xnb" : "Kanakanavu",
            "xpu" : "Punic",
            "xsy" : "Saisiyat",
            "yai" : "Yaghnobi",
            "yap" : "Yapese",
            "yav" : "Yangben",
            "ydg" : "Yidgha",
            "yec" : "Yenish",
            "yi" : "Yiddish",
            "yo" : "Yoruba",
            "yoi" : "Yonaguni",
            "yrk" : "Nenets",
            "yrl" : "Nheengatu",
            "yue" : "Cantonese",
            "yum" : "Quechan",
            "za" : "Zhuang",
            "zea" : "Zeelandic",
            "zgh" : "Standard Moroccan Amazigh",
            "zh" : "Chinese",
            "zh-cn" : "Putonghua",
            "zh-hans" : "Simplified Chinese",
            "zh-hant" : "Traditional Chinese",
            "zh-hk" : "Chinese (Hong Kong)",
            "zh-mo" : "Chinese in Macau",
            "zh-my" : "Malaysian Mandarin",
            "zh-sg" : "Singaporean Mandarin",
            "zh-tw" : "Chinese (taiwan)",
            "zsm" : "Standard Malay",
            "zu" : "Zulu",
            "zun" : "Zuni",
            "zxx" : "no linguistic content"
        };

        var map_data = languages[param];
    
        return map_data;
    }
    
}


