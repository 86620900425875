import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Globals } from '../global/global-variable';
import { LoginService } from '../services/login-service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent {
    form = this.fb.group({
        id : [''],
        action : ['update'],
        username : ['',Validators.required],
        password : ['', [Validators.required,Validators.minLength(6)]],
        confirm_password : ['', [Validators.required,Validators.minLength(6)]],
        password_placeholder : [''],
    });

    field_label = {
        "username" : "Email",
        "password" : "Password",
        "confirm_password" : "Confirm password"
    }

    show_change_password = true;
    //show password
    show_password = false;
    show_password2 = false;
    //end show password

    param = {}
    constructor(
        private toastr : ToastrService,
        private loginService : LoginService,
        public global : Globals,
        private fb : FormBuilder,
        private _activatedRouter : ActivatedRoute,
    ){
        this.param = this._activatedRouter.snapshot.params;
    }

    ngOnInit(){
        this.load_data();
    }//end if

    load_data(){
        const val = this.form.value;
        this.global.loading = true;

        try {
            const query_param = {
                "record_type" : environment.user_management,
                "pageSize" : 1,
                "is_public" : true,
                "filterFieldArr" : [
                    "id",
                    "for_password_change"
                ],
                "filterOperatorArr" : [
                    "==",
                    "=="
                ],
                "filterValueArr" : [
                    this.param["id"],
                    true
                ]
            }//end
            this.loginService.filterList(query_param).subscribe((response) => {
                console.log(response);
                if(response["success"]){
                    if(response["data"]["query_result"] > 0){
                        for(const k in this.form.controls){
                            const map_data = response["data"]["query_data"][0];
                            console.log(map_data);
                            for(const y in map_data){
                                if(k == 'password' || k == "confirm_password"){
                                    continue;
                                }//end if

                                if(k == y){
                                    this.form.controls[k].setValue(map_data[k]);
                                    break;
                                }//end if
                                if(k.includes('_placeholder')){
                                    if(k.replace('_placeholder','') == y){
                                        this.form.controls[k].setValue(map_data[k.replace("_placeholder","")]);
                                        break;
                                    }//end if
                                }//end if
                            }//end if
                        }//end if
                    }else{
                        
                        window.location.replace('login')
                    }//end if
                }else{

                }//end if
                this.global.loading = false
            })
        } catch (error : any) {
            
        }//end try
    }//end


    reset_password(){
        this.global.loading = true;
        const val = this.form.value;


        try {
            if(val.confirm_password.trim() !== ''){
                if(val.password !== val.confirm_password){
                    this.form.controls['confirm_password'].setErrors({"password_match" : true});
                }//end if
            }
            
           

            //validate login
            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['minlength'] !== undefined && field.errors?.['minlength']){
                        error_html_arr.push(this.field_label[k]+' must have 6 minimum characters <br/>');
                        
                    }//end if

                    if(field.errors?.['password_match'] !== undefined && field.errors?.['password_match']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t matched! <br/>');
                        
                    }//end if

                    if(field.errors?.['matchValidator'] !== undefined && field.errors?.['matchValidator']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t match! <br/>');
                        
                    }//end if
                }//end for

              
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

            val["username_placeholder"] = val.username;
            
            /*const request_param = {
                "is_public" : true,
                "records" : [{
                    "record_type"   : environment.user_management,
                    "for_password_change" : false,
                    ...val
                }]
            }*/

            const request_param = {
                "record_type"   : environment.user_management,
                "for_password_change" : false,
                ...val
            }

                

            this.loginService.createUserV2(request_param).subscribe((response) => {
                if(response["success"]){
                    this.show_change_password = false;
                }else{
                    this.toastr.error(response["data"]["error"]["message"],"System Message");
                }//end if
                this.global.loading = false;
            });


            /*

            this.loginService.createUser(request_param).then((response) => {
                console.log(response);
                if(response["success"]){
                    //this.toastr.success("Operation Successfully Completed!","System Message");
                    this.show_change_password = false;
                }else{
                    this.toastr.error(response["data"]["message"],"System Message");
                    
                }//end if
                this.global.loading = false;
            });
            */

        } catch (error : any) {
            console.log(error.message)
            //this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try
    }

    s_password(name){
        
        if(name == 'password'){
            if(this.show_password){
                this.show_password = false;
            }else{
                this.show_password = true;
            }//end if
            
        }else{
            if(this.show_password2){
                this.show_password2 = false;
            }else{
                this.show_password2 = true;
            }//end if
        }//end if
    }//end s_password
}
