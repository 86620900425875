import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../library/pcl_lib';
import { Globals } from '../global/global-variable';
import { DatePipe } from '@angular/common';
import { ManifestService } from '../services/manifest-service';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';
import {APP_BASE_HREF} from '@angular/common';
import { ToastrService } from 'ngx-toastr';
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var htmlToPdfmake = require("html-to-pdfmake");



@Component({
  selector: 'app-search-manifest',
  templateUrl: './search-manifest.component.html',
  styleUrls: ['./search-manifest.component.scss']
})
export class SearchManifestComponent {
    public qr_code_value = "";
    public qr_code_base64img : any;
    snapshot_param              = {};
    constructor(
        private _activatedRouter : ActivatedRoute,
        public commonService : CommonService,
        public global : Globals,
        private manifestService  : ManifestService,
        private toastr          : ToastrService,
        private datePipe : DatePipe){

        this.snapshot_param = this._activatedRouter.snapshot.params;
    }

    ngOnInit(){
        
        if(this.snapshot_param["module_type"] == "generate" || this.snapshot_param["module_type"] == "report"){
            this.load_sanction_report();
        }else if(this.snapshot_param["module_type"] == "generate-wiki" || this.snapshot_param["module_type"] == "report-wiki"){
            
            this.load_wikidata_report();
        }else if(this.snapshot_param["module_type"] == "generate-wiki-single" || this.snapshot_param["module_type"] == "report-wiki-single"){
            this.load_wikidata_report_single();
        }else if(this.snapshot_param["module_type"] == "generate-adverse" || this.snapshot_param["module_type"] == "report-adverse"){
            this.load_adverse_report()
        }else if(this.snapshot_param["module_type"] == "generate-adverse-single" || this.snapshot_param["module_type"] == "report-adverse-single"){
            this.load_adverse_report_single();
        }else if(this.snapshot_param["module_type"] == "generate-single" || this.snapshot_param["module_type"] == "report-single"){
            this.load_report_single();
        }//end if//end if
    }


    async load_sanction_report(){
        this.global.loading    = true;
        const data_api = await lastValueFrom(this.refresh_list(this.snapshot_param["id"]));
        const data = data_api["data"];
        if(data["total_result"] > 0){
            var query_filter = data['query_data'][0]['query_filter'];
            var pdf_name = "untitled.pdf";
            var email_subject = "Untitled";
            for(var k in query_filter){
                const m_data = query_filter[k];

                if(!environment.enable_elastic){
                    if(m_data['field'] == 'SEARCH_REFERENCE_NUMBER'){
                        pdf_name = m_data['value']+'.pdf';
                        email_subject = m_data['value'];
                    }else if(m_data['field'] == 'FIRST_NAME' || m_data['field'] == 'SEARCH_FULL_NAME'){
                        pdf_name = m_data['value']+'.pdf';
                        email_subject = m_data['value'];
                        break;
                    }else if(m_data['field'] == 'SEARCH_SOURCE_LIST'){
                        
                        pdf_name = m_data['value']+'.pdf';
                        email_subject = m_data['value'];
                    }//end if
                }else{
                    if(m_data['field'].toLowerCase() == 'reference_number'){
                        pdf_name = m_data['value']+'.pdf';
                        email_subject = m_data['value'];
                    }else if(m_data['field'].toLowerCase().indexOf('name') >= 0){
                        pdf_name = m_data['value']+'.pdf';
                        email_subject = m_data['value'];
                        break;
                    }else if(m_data['field'].toLowerCase() == 'source_list'){
                        pdf_name = m_data['value']+'.pdf';
                        email_subject = m_data['value'];
                    }//end if//end if
                }//end if
                
            }

            var request_param = {
                "id" : this.snapshot_param['id'],
                "search_type" : this.snapshot_param['search_type'],
                "file_name" : pdf_name,
                "url" : window.location.origin+"/search-result/report/"+this.snapshot_param['search_type']+"/"+this.snapshot_param['id']
            }//end
    
            console.log('request_param',request_param)
            
            this.manifestService.generate_manifest(request_param).subscribe((manifest_api)=>{
                console.log(manifest_api);
                if(manifest_api["success"]){
                    var url = manifest_api["data"]["manifest_link"];
                    window.location.replace(url);
                }//end if
                
            });

            if(this.snapshot_param["module_type"].toLowerCase() == 'generate'){
                window.setTimeout(function(){
                    var message = 'Your report is currently being generated. You may wait for it to load in this page. It will also be available in the "Reports" page within 5 minutes.';
                    alert(message);
                },2000);
            }//end if


            /*window.setTimeout(function(){
                var message = "An email will be sent to you once manifest generation has been finished. Click ok to close this window or wait for it!";
                //alert(message);

                if(confirm(message)){
                    window.close();
                }//end if
            },2000);
            */
        }else{
            window.close();
        }//end if
    }//end if


    load_wikidata_report(){
        this.global.loading = true;
        var wikidata_id = new Array();

        var page = this.snapshot_param["page"];
        
        var request_param = {
            "search_id" : this.snapshot_param["id"],
            "file_name" : "",
            "page" : (page),
            "url" : "",
            "wikidata_id" : wikidata_id
        };
        console.log(request_param);
        this.manifestService.generate_manifest_wikidata(request_param).subscribe(result => {
        
            if(result["success"]){
                if(result["data"]["manifest_link"] !== undefined){
                    const url = result["data"]["manifest_link"];
                    window.location.replace(url);
                }else{
                    this.toastr.error("Report not yet generated!","System Message");
                }//end if
            }else{
                console.log(result);
                this.toastr.error("Failed to generate report!","System Message")
            }//end if
            this.global.loading = false;
        });

   
        
    }//end

    load_wikidata_report_single(){
        this.global.loading = true;
        var wikidata_id = new Array();

        var request_param = {
            "created_by" : "na",
            "file_name" : "",
            "search_id" : this.snapshot_param["id"],
            "url" : "",
            "wikidata_id" : wikidata_id
        };
        
        this.manifestService.generate_manifest_wikidata_single(request_param).subscribe(result => {
        
            if(result["success"]){
                if(result["data"]["manifest_link"] !== undefined){
                    const url = result["data"]["manifest_link"];
                    window.location.replace(url);
                }else{
                    this.toastr.error("Report not yet generated!","System Message");
                }//end if
            }else{
                console.log(result);
                this.toastr.error("Failed to generate report!","System Message")
            }//end if
            this.global.loading = false;
        });

   
        
    }//end


    load_report_single(){
        this.global.loading = true;
        var wikidata_id = new Array();

        var request_param = {
            "created_by" : "na",
            "file_name" : "",
            "search_id" : this.snapshot_param["id"],
            "url" : ""
        };
        
        this.manifestService.generate_manifest_single(request_param).subscribe(result => {
        
            if(result["success"]){
                if(result["data"]["manifest_link"] !== undefined){
                    const url = result["data"]["manifest_link"];
                    window.location.replace(url);
                }else{
                    this.toastr.error("Report not yet generated!","System Message");
                }//end if
            }else{
                console.log(result);
                this.toastr.error("Failed to generate report!","System Message")
            }//end if
            this.global.loading = false;
        });

   
        
    }//end


    load_adverse_report(){
        this.global.loading = true;
        var wikidata_id = new Array();

        var page = this.snapshot_param["page"];
        
        var request_param = {
            "search_id" : this.snapshot_param["id"],
            "file_name" : "",
            "url" : ""
        };
        console.log(request_param);
        this.manifestService.generate_manifest_adverse(request_param).subscribe(result => {
        
            if(result["success"]){
                if(result["data"]["manifest_link"] !== undefined){
                    const url = result["data"]["manifest_link"];
                    window.location.replace(url);
                }else{
                    this.toastr.error("Report not yet generated!","System Message");
                }//end if
            }else{
                console.log(result);
                this.toastr.error("Failed to generate report!","System Message");
            }//end if
            this.global.loading = false;
        });
    }//end


    load_adverse_report_single(){
        this.global.loading = true;
        var wikidata_id = new Array();

        var page = this.snapshot_param["page"];
        
        var request_param = {
            "search_query" : "none",
            "file_name" : "none",
            "search_id" : this.snapshot_param["id"],
            "created_by" : "",
            "search_type": this.snapshot_param['search_type'],
            "url" : "none"
        };
        console.log(request_param);
        this.manifestService.generate_manifest_adverse_single(request_param).subscribe(result => {
        
            if(result["success"]){
                if(result["data"]["manifest_link"] !== undefined){
                    const url = result["data"]["manifest_link"];
                    window.location.replace(url);
                }else{
                    this.toastr.error("Report not yet generated!","System Message");
                }//end if
            }else{
                console.log(result);
                this.toastr.error("Failed to generate report!","System Message");
            }//end if
            this.global.loading = false;
        });
    }//end



    async load_wikidata_report2(){
        this.global.loading    = true;
        console.log(this.snapshot_param["id"]);
        const data_api = await lastValueFrom(this.refresh_list2(this.snapshot_param["id"]));
        const data = data_api["data"];
        console.log("data",data);
        if(data["total_result"] > 0){
            const query_data = data["query_data"][0];
            if(query_data["manifest_link"] !== undefined){


                var url = query_data["manifest_link"];
                window.location.replace(url);
            }else{
                this.toastr.error("PEP manifest hasn't been generated yet. Please generate it first using generate button!","System Message")
            }//end if
            
        }else{
            window.close();
        }//end if
    }//end if



    async ngOnInitBak(){


        this.global.loading    = true;

        var qr_link = window.location.origin;
        console.log(this.snapshot_param["id"]);
        qr_link += "/search-result/" + this.snapshot_param['search_type']+'/'+this.snapshot_param["id"];
        this.qr_code_value = qr_link;
        
        
        const data_api = await lastValueFrom(this.refresh_list(this.snapshot_param["id"]));
        console.log('data_api',data_api);
        const sources_api = await lastValueFrom(this.get_sources(this.snapshot_param['search_type']));
        console.log('sources_api',sources_api);
        const company_info = await lastValueFrom(this.company_info())
        console.log('company_info',company_info);
        const company = company_info["data"]["query_data"]
        console.log(company_info);
        //get logo
        var param = {}
        await getBase64ImageFromURL('../../assets/images/UNAWA Logo Horizontal - with TM 2.png').then(result => {
            param["company_logo"] = result;
            
        });

        await getBase64ImageFromURL('../../assets/images/manifest-check.png').then(result => {
            param["manifest_check"] = result;
        });

        await getBase64ImageFromURL('../../assets/images/report_bg.png').then(result => {
            param["report_bg"] = result;
        });
        //end get logo

        
        param['qr_code_base64img'] = this.qr_code_base64img;

        param = {
            ...this.snapshot_param,
            ...param,
            ...data_api["data"],
            ...company[0],
            "source_list_data" : sources_api['data']
        };

        var query_filter = param['query_data'][0]['query_filter'];
        var pdf_name = "untitled.pdf";
        var email_subject = "Untitled";
        for(var k in query_filter){
            const m_data = query_filter[k];

            if(!environment.enable_elastic){
                if(m_data['field'] == 'SEARCH_REFERENCE_NUMBER'){
                    pdf_name = m_data['value']+'.pdf';
                    email_subject = m_data['value'];
                }else if(m_data['field'] == 'FIRST_NAME' || m_data['field'] == 'SEARCH_FULL_NAME'){
                    pdf_name = m_data['value']+'.pdf';
                    email_subject = m_data['value'];
                    break;
                }else if(m_data['field'] == 'SEARCH_SOURCE_LIST'){
                    pdf_name = m_data['value']+'.pdf';
                    email_subject = m_data['value'];
                }//end if
            }else{
                if(m_data['field'].toLowerCase() == 'reference_number'){
                    pdf_name = m_data['value']+'.pdf';
                    email_subject = m_data['value'];
                }else if(m_data['field'].toLowerCase().indexOf('name') >= 0){
                    pdf_name = m_data['value']+'.pdf';
                    email_subject = m_data['value'];
                    break;
                }else if(m_data['field'].toLowerCase() == 'source_list'){
                    pdf_name = m_data['value']+'.pdf';
                    email_subject = m_data['value'];
                }//end if//end if
            }//end if
            
        }

        //console.log(param['query_data'][0]);
        
        param['pdf_name'] = pdf_name;
        
        const template = generate_manifest(param,this.datePipe,this.commonService);
        var win = window.open('', '_self');
        //var test = pdfMake.createPdf(template).open({},win);
        var fonts = {
            Src_Code: {normal: 'https://cdn.jsdelivr.net/npm/source-code-pro@2.30.2/TTF/SourceCodePro-Regular.ttf'},
            Vazir: {normal: 'https://cdn.jsdelivr.net/gh/rastikerdar/vazir-font@v27.2.2/dist/Vazir-Regular.ttf'},
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            },
            Noto_Sans : {
                italics : window.location.origin+'/assets/fonts/noto-sans/NotoSans-Italic.ttf',
                normal : window.location.origin+'/assets/fonts/noto-sans/NotoSans-Regular.ttf',
                bold : window.location.origin+'/assets/fonts/noto-sans/NotoSans-Bold.ttf'
            },
            Noto_Sans_Arabic : {
                normal : window.location.origin+'/assets/fonts/unicode/Arial Unicode MS.TTF',
                
                //normal : window.location.origin+'/assets/fonts/open-sans/OpenSans-Regular.ttf',
                //bold : window.location.origin+'/assets/fonts/open-sans/OpenSans-Bold.ttf'
            }
           
        };

       
        var test = pdfMake.createPdf(template,null,fonts).download(pdf_name);


        var data_param = {
            "manifest_id" : this.snapshot_param["id"],
            "manifest_type" : this.snapshot_param['search_type'],
            "report_title" : pdf_name,
            "subject" : email_subject,
            
        }//end

        if(this.global['login_session'] !== undefined && this.global['login_session'] !== null){
            if(this.global.login_session["id"] !== undefined){
                data_param["created_by"] = this.global.login_session["id"];
            }
        }else{
            data_param["created_by"] = 0;
        }//end if
        

        const send_email_api = await lastValueFrom(this.manifestService.sendManifest(data_param));
        window.setTimeout(function(){
            window.close();
        },2000);

        this.global.loading    = false;

        

        //
        
    }//end

    refresh_list(search_str){
            let filters = {};
            if(search_str.trim() !== ""){
                filters["filterFieldArr"]       = new Array();
                filters["filterOperatorArr"]    = new Array();
                filters["filterValueArr"]       = new Array();
                filters["filterFieldArr"].push("id");
                filters["filterOperatorArr"].push("==");
                filters["filterValueArr"].push(search_str.trim());
            }//end if

            

            var data_param = {
                "is_public" : true,
                "record_type" : environment.dashboard_counter,
                "pageSize" : 1000,
                
                "search_type" : this.snapshot_param['search_type'],
                ...filters
            }//end

            

            if(this.global['login_session'] !== undefined && this.global['login_session'] !== null){
                if(this.global.login_session["id"] !== undefined){
                    //data_param["created_by"] = this.global.login_session["id"];
                }
            }else{
                //data_param["created_by"] = 0;
            }//end if

            return this.manifestService.filterList(data_param);
    }//end if

    refresh_list2(search_str){
        let filters = {};
        if(search_str.trim() !== ""){
            filters["filterFieldArr"]       = new Array();
            filters["filterOperatorArr"]    = new Array();
            filters["filterValueArr"]       = new Array();
            filters["filterFieldArr"].push("id");
            filters["filterOperatorArr"].push("==");
            filters["filterValueArr"].push(search_str.trim());
        }//end if

        

        var data_param = {
            "is_public" : false,
            "record_type" : environment.dashboard_counter_wiki,
            "pageSize" : 1000,
            "search_type" : this.snapshot_param['search_type'],
            ...filters
        }//end

        

        if(this.global['login_session'] !== undefined && this.global['login_session'] !== null){
            if(this.global.login_session["id"] !== undefined){
                //data_param["created_by"] = this.global.login_session["id"];
            }
        }else{
            //data_param["created_by"] = 0;
        }//end if

        return this.manifestService.filterList(data_param);
    }//end if



    get_sources(search_type){
       var record_type = environment.source_list
       if(search_type == 'entity'){
            record_type = environment.entity_source_list;
       }//end if

        

        var data_param = {
            "is_public" : true,
            "record_type" : record_type,
            "pageSize" : 1000,
            
        }//end
        return this.manifestService.filterList(data_param);
      }

    company_info(){
        
            var data_param = {
                "record_type" : environment.company_information,
                "is_public" : true,
                "pageSize" : 1
            }//end
            return this.manifestService.filterList(data_param);
    }//end if

    onChangeURL(url : SafeUrl) {
        const base64Img = document.getElementsByClassName('qrcode')[0].children[0]['src'];
        //const cnvs      = document.getElementsByClassName('qrcode')[0].children[0];
        this.qr_code_base64img = base64Img;
    }
}


function generate_manifest(param,d_pipe,commonService){
   
    var date = "";

    var search_type = "";

    var alias_label = "";
    if(param['search_type'] == 'individual'){
        search_type = 'individual';
        alias_label = "Good quality aliase(s)";
    }else{
        search_type = 'entity';
        alias_label = "Aliase(s)";
    }//end if
    

    const profile = param["query_data"][0]["profile"];
    var query_data = param["query_data"][0];

    if(param["query_data"] !== undefined){
        for(const k in param["query_data"]){
            var map_data = param["query_data"][k];
            const _date = new Date(new Date(map_data["date_created"]["_seconds"] * 1000 + map_data["date_created"]["_nanoseconds"]/1000000))
            date = d_pipe.transform(_date, 'MMM dd, yyyy hh:mm:ss a');
        }//end if
    }//end if


    //search query
    var s_query_body = new Array();
    var s_query_arr = param["query_data"][0]['query_filter'];

    var search_criteria = new Array();
    var sources_arr = new Array();
    for(var k in s_query_arr){
        var m_data = s_query_arr[k];

        if(m_data['field'].toLowerCase().indexOf('individual_alias') < 0 && m_data['field'].toLowerCase().indexOf('name') >= 0){
            m_data['field'] = 'Name';
            
        }else if(m_data['field'].toLowerCase().indexOf('individual_alias') >= 0){
            m_data['field'] = 'Alias';
        }else if(m_data['field'].toLowerCase().indexOf('reference_number') >= 0){
            m_data['field'] = 'List Reference';
            
        }else if(m_data['field'].toLowerCase().indexOf('source_list') >= 0){
            sources_arr.push(m_data['value'])
            m_data['field'] = 'Source';
            
        }//end if

        if(m_data['field'].toLowerCase().indexOf('individual_address') >= 0){
            m_data['field'] = 'Address';
            
        }//end if

        if(m_data['field'].toLowerCase().indexOf('date_placeholder') >= 0){
            m_data['field'] = 'Date of birth';
            
        }//end if

        if(m_data['field'].toLowerCase().indexOf('day_placeholder') >= 0){
            m_data['field'] = 'Date of birth(Day)';
            
        }//end if

        if(m_data['field'].toLowerCase().indexOf('month_placeholder') >= 0){
            m_data['field'] = 'Date of birth(Month)';
            
        }//end if

        if(m_data['field'].toLowerCase().indexOf('year_placeholder') >= 0){
            m_data['field'] = 'Date of birth(Year)';
            
        }//end if

        if(m_data['field'].toLowerCase().indexOf('individual_place_of_birth') >= 0){
            m_data['field'] = 'Place of birth';
            
        }//end if

        if(m_data['field'].toLowerCase().indexOf('comments') >= 0){
            m_data['field'] = 'Comments';
            
        }//end if

        if(m_data['field'].toLowerCase().indexOf('entity_address') >= 0){
            m_data['field'] = 'Address';
            
        }//end if
        
        if(m_data['field'].toLowerCase().indexOf('query') >= 0){
            continue;
        }//end if

        if(m_data['field'].toLowerCase().indexOf('.date') >= 0){
            continue;
        }//end if

        const criteria = commonService.ucwords(m_data['field']) + ' : ' + m_data['value'];

       

        const subArr = search_criteria.some(str => str.includes(commonService.ucwords(m_data['field'])));

        if(subArr){
            continue;
        }
        
        search_criteria.push(criteria);        
    }//end for

    
    search_criteria = commonService.removeDuplicates(search_criteria);
    var search_criteria_str = "";
    if(search_criteria.length > 0){
        search_criteria_str = search_criteria.join('\n');
        
    }//end if

    s_query_body.push([
        {
            text : 'Search Query :',
            alignment : 'left',
            style: 'tableLabel'
        },
        {
            text : search_criteria_str,
            alignment : 'left',
            style: 'fontRegular',
            font: 'Noto_Sans_Arabic'
        }
    ]);

    
    

    var result_body = new Array();
    var result_remarks = new Array();
    //set title
    result_body.push([
        {
            text : '',
            alignment : 'left',
            style: 'tableLabel'
        },
        {
            text : '',
            alignment : 'left',
            style: 'tableLabel'
        }
    ],
    [
        {
            text : 'Results',
            alignment : 'left',
            style: 'tableLabel',
            colSpan : 2,
            margin : [0, 10, 0, 10]
           
        }
    ]);
    //end set title
    

    if(parseInt(param["query_data"][0]["query_result"]) > 1){
        if(param['search_type'] == 'individual'){
            search_type = 'individuals';
        }else{
            search_type = 'entities';
        }//end if
    }//end if

    
    
    if(parseInt(param["query_data"][0]["query_result"]) <= 0){
        result_body.push([
            {
                stack : [
                    {
                        image: 'check',
                        width : 13,
                        height : 13,
                        alignment : 'left',
                        
                        
                    }
                ],
                margin : [0, 0, 0, 15]
            },
            {
                text : "No Match Found",
                style : 'fontRegular',
                alignment : 'left',
                margin : [0, 0, 0, 15]
            }
        ],
        [
            {
                text : "Remarks",
                style : 'tableLabel',
                alignment : 'left',
                colSpan : 2,
                margin : [0, 0, 0, 0]
            }
        ],
        [
            {
                
                text : [
                    {text: 'No matches found. ', bold: true},
                    'The search Query below was executed, but the search yielded no matching individuals.'
                ],
                style : 'fontRegular',
                alignment : 'left',
                colSpan : 2,
                margin : [0, 0, 0, 20]
            }
        ]
        );
    }else{
        result_body.push([
            {
                stack : [
                    {
                        image: 'check',
                        width : 13,
                        height: 13,
                        alignment : 'left'
                        
                    }
                ],
                margin : [0, 0, 0, 15]
            },
            {
                text : "Possible Match/es Found",
                style : 'fontRegular',
                alignment : 'left',
                margin : [0, 0, 0, 15]
            }
        
        ]);

        result_body.push([
            {
                text : 'Remarks',
                alignment : 'left',
                style: 'tableLabel',
                colSpan : 2
               
            }
        ],
        [
            {
                text : [
                    {text: 'Possible Match/es Found. ', bold: true},
                    'The Search Query below was executed, yielding potential matching '+search_type+' with the following information identified.'
                ],
                alignment : 'left',
                style: 'fontRegular',
                colSpan : 2
               
            }
        ]);

        
        
        for(const k in param["query_data"]){
            const map_data = param["query_data"][k];
            const query_hits = map_data["query_hits"];

            
           

            for(const z in query_hits){
                var m_data = query_hits[z];
                if(environment.enable_elastic){
                    var soure = m_data['_source'];
                }else{
                    var soure = m_data;
                }
                

                var address = "";
                var place_of_birth = "";
                var dob = "";
                var nationality = "";
                
                //-----------------INDIVIDUAL_ADDRESS-----------------
                

                if(param['search_type'] == 'entity'){
                    soure["INDIVIDUAL_ADDRESS"] = soure["ENTITY_ADDRESS"];
                    soure["FULL_NAME"] = soure["FIRST_NAME"];
                }//end if
                
                
                for(const x in soure["INDIVIDUAL_ADDRESS"]){
                    const data = soure["INDIVIDUAL_ADDRESS"][x];

                    if(data["STREET"] !== undefined){
                        address += data["STREET"] + " ";
                    }//end if

                    if(data["STATE_PROVINCE"] !== undefined){
                        address += data["STATE_PROVINCE"] + " ";
                    }//end if

                    if(data["CITY"] !== undefined){
                        address += data["CITY"] + " ";
                    }//end if

                    if(data["COUNTRY"] !== undefined){
                        address += data["COUNTRY"] + " ";
                    }//end if
                }//end for
                //-----------------INDIVIDUAL_ADDRESS-----------------

                //-----------------INDIVIDUAL_PLACE_OF_BIRTH-----------------
                if(param['search_type'] == 'individual'){
                    for(const x in soure["INDIVIDUAL_PLACE_OF_BIRTH"]){
                        const data = soure["INDIVIDUAL_PLACE_OF_BIRTH"][x];

                        if(data["STREET"] !== undefined){
                            place_of_birth += data["STREET"] + " ";
                        }//end if

                        if(data["STATE_PROVINCE"] !== undefined){
                            place_of_birth += data["STATE_PROVINCE"] + " ";
                        }//end if

                        if(data["CITY"] !== undefined){
                            place_of_birth += data["CITY"] + " ";
                        }//end if

                        if(data["COUNTRY"] !== undefined){
                            place_of_birth += data["COUNTRY"] + " ";
                        }//end if
                    }//end for
                }//end if
                //-----------------INDIVIDUAL_PLACE_OF_BIRTH-----------------

                //-----------------INDIVIDUAL_DATE_OF_BIRTH-----------------
                if(param['search_type'] == 'individual'){
                    for(const x in soure["INDIVIDUAL_DATE_OF_BIRTH"]){
                        const data = soure["INDIVIDUAL_DATE_OF_BIRTH"][x];

                        if(data["DATE"] !== undefined){
                            dob = data["DATE"];

                            /*const _date = new Date(dob);
                            const _date2 = d_pipe.transform(_date, 'MMM dd, yyyy');
                            dob = _date2;
                            */
                        }else if(data["YEAR"] !== undefined){
                            dob = data["YEAR"];
                        }else if(data["FROM_YEAR"] !== undefined || data["TO_YEAR"] !== undefined){
                            dob = data["FROM_YEAR"] + ' - ' + data["TO_YEAR"];
                        }//end if
                    }//end for
                }//end if
                //-----------------INDIVIDUAL_DATE_OF_BIRTH-----------------

                //-----------------NATIONALITY-----------------
                if(param['search_type'] == 'individual'){
                    if(soure['NATIONALITY'] !== undefined){
                        nationality = soure['NATIONALITY'].join(',');
                    }//end if
                }//end if
                //-----------------NATIONALITY-----------------

                


                //-----------------INDIVIDUAL_ALIAS-----------------
                var g_alias_arr = new Array();
                var l_alias_arr = new Array();

                if(param['search_type'] == 'individual'){
                    for(const x in soure["INDIVIDUAL_ALIAS"]){
                        const data = soure["INDIVIDUAL_ALIAS"][x];
                        if(data["QUALITY"] === undefined){
                            continue;
                        }//end if

                        if(data["QUALITY"].toLowerCase() == 'good'){
                            g_alias_arr.push(data["ALIAS_NAME"]);
                        }//end if

                        if(data["QUALITY"].toLowerCase() == 'low'){
                            l_alias_arr.push(data["ALIAS_NAME"]);
                        }//end if
                        
                    }//end for
                }else{
                    for(const x in soure["ENTITY_ALIAS"]){
                        const data = soure["ENTITY_ALIAS"][x];
                        if(data["QUALITY"] === undefined){
                            continue;
                        }//end if
                        g_alias_arr.push(data["ALIAS_NAME"]);
                    
                    }//end for
                }//end if
                //-----------------INDIVIDUAL_ALIAS-----------------


                //-----------------INDIVIDUAL_DOCUMENT-----------------
                var docs = new Array();
                if(param['search_type'] == 'individual'){
                    for(const x in soure["INDIVIDUAL_DOCUMENT"]){
                        const data = soure["INDIVIDUAL_DOCUMENT"][x];
                        if(data["TYPE_OF_DOCUMENT"] === undefined){
                            continue;
                        }//end if

                        if(data["TYPE_OF_DOCUMENT"].toLowerCase() == 'passport'){
                            docs.push(data["NUMBER"]);
                        }//end if

                        
                    }//end for
                }//end if
                //-----------------INDIVIDUAL_DOCUMENT-----------------


                
                


                result_remarks.push([
                    {
                        text : '',
                        alignment : 'left',
                        style: 'fontRegular',
                        
                    },
                    {
                        text : 'Match ' + (parseInt(z)+1),
                        alignment : 'left',
                        style: 'fontRegular'
                        
                    },
                    {
                        text : '',
                        alignment : 'left',
                        style: 'fontRegular'
                        
                    }
                    
                ]);

                result_remarks.push([
                    {
                        text : '',
                        alignment : 'left',
                        style: 'tableLabel'
                    },
                    {
                        text : 'Name :',
                        alignment : 'left',
                        style: 'tableLabel'
                    },
                    {
                        text : soure["FULL_NAME"],
                        alignment : 'left',
                        style: 'fontRegular'
                    }
                ]);
                
                if(param['search_type'] == 'individual'){
                    result_remarks.push([
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : 'Nationality :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : nationality,
                            alignment : 'left',
                            style: 'fontRegular'
                        }
                    ]);
           
                    result_remarks.push([
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : 'Date of Birth :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : dob,
                            alignment : 'left',
                            style: 'fontRegular'
                        }
                    ]);
                
                    result_remarks.push([
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : 'Place of Birth :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : place_of_birth,
                            alignment : 'left',
                            style: 'fontRegular'
                        }
                    ]);
                }//end if
                

                for(var x in g_alias_arr){
                    var cols = new Array()
                    if(parseInt(x) == 0){
                        cols.push(
                            {
                                text : '',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : alias_label+' :',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : g_alias_arr[x],
                                alignment : 'left',
                                style: 'fontRegular',
                                font: 'Noto_Sans_Arabic'
                            }
                        );
                    }else{
                        cols.push(
                            {
                                text : '',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : '',
                                alignment : 'left',
                                style: 'fontRegular',
                                font: 'Noto_Sans_Arabic'
                            },
                            {
                                text : g_alias_arr[x],
                                alignment : 'left',
                                style: 'fontRegular',
                                font: 'Noto_Sans_Arabic'
                            }
                        );
                    }//end if
                    result_remarks.push(cols);
                }//end for

                if(param['search_type'] == 'individual'){
                    for(var x in l_alias_arr){
                        var cols = new Array()
                        if(parseInt(x) == 0){
                            cols.push(
                                {
                                    text : '',
                                    alignment : 'left',
                                    style: 'tableLabel'
                                },
                                {
                                    text : 'Low quality aliase(s) :',
                                    alignment : 'left',
                                    style: 'tableLabel'
                                },
                                {
                                    text : l_alias_arr[x],
                                    alignment : 'left',
                                    style: 'fontRegular',
                                    font: 'Noto_Sans_Arabic'
                                }
                            );
                        }else{
                            cols.push(
                                {
                                    text : '',
                                    alignment : 'left',
                                    style: 'tableLabel'
                                },
                                {
                                    text : '',
                                    alignment : 'left',
                                    style: 'tableLabel'
                                },
                                {
                                    text : l_alias_arr[x],
                                    alignment : 'left',
                                    style: 'fontRegular',
                                    font: 'Noto_Sans_Arabic'
                                }
                            );
                        }//end if
                        result_remarks.push(cols);
                    }//end for
                }//end if


               

                for(var x in docs){
                    var cols = new Array();
                    var mm_data = docs[x];
                    
                    if(parseInt(x) == 0){
                        cols.push(
                            {
                                text : '',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : 'Passport Number/s :',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : mm_data,
                                alignment : 'left',
                                style: 'fontRegular'
                            }
                        );
                    }else{
                        cols.push(
                            {
                                text : '',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : '',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : mm_data,
                                alignment : 'left',
                                style: 'fontRegular'
                            }
                        );
                    }//end if
                    result_remarks.push(cols);
                }//end for

                



                result_remarks.push([
                    {
                        text : '',
                        alignment : 'left',
                        style: 'tableLabel'
                    },
                    {
                        text : 'Address :',
                        alignment : 'left',
                        style: 'tableLabel'
                    },
                    {
                        text : address,
                        alignment : 'left',
                        style: 'fontRegular'
                    }
                ]);


                for(var x in soure["DESIGNATION"]){
                    var cols = new Array();
                    var mm_data = soure["DESIGNATION"][x];
                    mm_data = mm_data.replace('\n',' ');
                    if(parseInt(x) == 0){
                        cols.push(
                            {
                                text : '',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : 'Designation :',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : mm_data,
                                alignment : 'left',
                                style: 'fontRegular'
                            }
                        );
                    }else{
                        cols.push(
                            {
                                text : '',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : '',
                                alignment : 'left',
                                style: 'tableLabel'
                            },
                            {
                                text : mm_data,
                                alignment : 'left',
                                style: 'fontRegular'
                            }
                        );
                    }//end if
                    result_remarks.push(cols);
                }//end for

                if(soure["NAME_ORIGINAL_SCRIPT"] !== undefined){
                    result_remarks.push([
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : 'Name Original Script :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : soure["NAME_ORIGINAL_SCRIPT"],
                            alignment : 'left',
                            style: 'fontRegular',
                            font: 'Noto_Sans_Arabic'
                        }
                    ]);
                }//end if

                if(soure["COMMENTS1"] !== undefined){
                    result_remarks.push([
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : 'Comments :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : soure["COMMENTS1"],
                            alignment : 'left',
                            style: 'fontRegular',
                            font: 'Noto_Sans_Arabic'
                        }
                    ]);
                }//end if

                if(soure["LISTED_ON"] !== undefined){

                 
                    soure["LISTED_ON"] = new Date(soure["LISTED_ON"]);
                    var listed_on_parsed = "";
                    if (Object.prototype.toString.call(soure["LISTED_ON"]) === "[object Date]") {
                        // it is a date
                        if (isNaN(soure["LISTED_ON"])) { // d.getTime() or d.valueOf() will also work
                            listed_on_parsed = "";
                        } else {
                            // date object is valid
                            listed_on_parsed = d_pipe.transform((soure["LISTED_ON"]), 'MMM dd, yyyy');
                        }//end if
                    }else{
                        listed_on_parsed = "";
                    }//end
                    
                    result_remarks.push([
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : 'Listed on :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : listed_on_parsed,
                            alignment : 'left',
                            style: 'fontRegular'
                        }
                    ]);
                }//end if


                if(soure["REFERENCE_NUMBER"] !== undefined){
                    result_remarks.push([
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : 'Ref. no. :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : soure["REFERENCE_NUMBER"],
                            alignment : 'left',
                            style: 'fontRegular'
                        }
                    ]);
                }//end if


                if(soure["SOURCE_LIST"] !== undefined){
                    
                    result_remarks.push([
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : 'Source :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : soure["SOURCE_LIST"],
                            alignment : 'left',
                            style: 'fontRegular'
                        }
                    ]);
                }//end if

                
                

                result_remarks.push([
                    {
                        text : '',
                        alignment : 'left',
                        style: 'tableLabel',
                        colSpan : 3,
                        margin : [0,0,0,20]
                    }
                ])
                
            }//end for

            
            
        }//end for


    }//end if

    
    var template = [
        {
            stack: [
                {
                    style: 'tableExample',
                    margin : [0, 0, 0, 28],
                    
                    table: {
                        heights: [40],
                        widths: ['*','*'],
                        body: [
                            [
                               
                                {
                                    stack : [
                                        {

                                            image: 'unawa_logo',
                                            width : 118,
                                            height: 28,
                                            alignment : 'left',
                                        }
                                    ],
                                    border: [false, false, false, false],
                                },
                                {
                                    text : "UCheck Manifest Report",
                                    style : 'tableHeader',
                                    alignment : 'right',
                                    border: [false, false, false, false],
                                }
                            ],
                            [
                                {
                                    text : "",
                                    colSpan : 2,
                                    border: [false, false, false, true],
                                }
                            ]
                        ]
                    },
                    layout: {
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? '#C2C2C1' : '#C2C2C1';
                        },
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                        }
                    }
                }
            ],
            style: 'tableInfo'
            
        },
        {
            style: 'tableExample',
            margin : [0, 0, 0, 20],
            table : {
                
                widths: [31,'*'],
                body: [
                    [
                        {
                            text : 'Date :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : date,
                            alignment : 'left',
                            style: 'fontRegular'
                        }
                    ]
                ]
                
            },
            layout: 'noBorders'
        },

        {
            style: 'tableExample',
            margin : [0, 0, 0, 0],
            table : {
                widths: [100,'*'],
                body: s_query_body
            },
            layout: 'noBorders'
        },
        
        {
            style: 'tableExample',
            margin : [0, 20, 0, 0],
            table : {
                widths: [20,'*'],
                body: result_body
            },
            layout: 'noBorders'
        }
    ];

    if(result_remarks.length > 0){
        template.push(
            {
                style: 'tableExample',
                margin : [0, 20, 0, 0],
                table : {
                    widths: [50,150,'*'],
                    body: result_remarks
                },
                layout: 'noBorders'
            }
        );
    }//end if

    

    if(sources_arr.length <= 0){
        if(param["source_list_data"]["query_data"] !== undefined){
            var source_list_data = param["source_list_data"]["query_data"];
            for(const s in source_list_data){
                const m_data = source_list_data[s];
                sources_arr.push(m_data["name"])
            }//end for
        }//end if
    }//end if

    
    
    //if(param['search_type'] == 'individual'){
        //individual

        if(sources_arr.length > 0){
            var source_body = new Array();
            for(const s in sources_arr){
                const m_data = sources_arr[s];
                source_body.push([
                    {
                        
                        text : "",
                        alignment : 'left',
                        style: 'fontRegular',
                    },
                    {
                        
                        text : (parseInt(s) + 1)+".",
                        alignment : 'left',
                        style: 'fontRegular',
                    },
                    {
                        
                        text : commonService.ucwords(m_data),
                        alignment : 'left',
                        style: 'fontRegular',
                    }
                ]);
            }//end for

            template.push({
                style: 'tableExample',
                margin : [0, 0, 0, 20],
                table : {
                    widths: [20,'*'],
                    body: [
                        [
                            {
                                colSpan : 2,
                                text : "Searched Database(s):",
                                alignment : 'left',
                                style: 'tableLabel',
                            }
                        ]   
                    ]
                },
                layout: 'noBorders'
            });

            template.push({
                style: 'tableExample',
                margin : [0, 0, 0, 0],
                table : {
                    widths: [5,10,'*'],
                    body: source_body
                },
                layout: 'noBorders'
            });
        }//end if
    //}else{
        //entity

    //}
    

    

    template.push(
        {
            style: 'tableExample',
            margin : [0, 0, 0, 0],
            table : {
                widths: [31,'*'],
                body: [
                    [
                        {
                            colSpan : 2,
                            text : 'UChecked by :',
                            alignment : 'left',
                            style: 'tableLabel',
                            margin : [0, 20, 0, 0],
                        }
                    ]
                ]
            },
            layout: 'noBorders'
        },

        {
            style: 'tableExample',
            margin : [0, 0, 0, 0],
            table : {
                widths: [39,'*'],
                body: [
                    [
                        {
                            text : 'Name :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : profile["first_name"]+' '+profile["last_name"],
                            alignment : 'left',
                            style: 'fontRegular'
                        }
                    ]
                ]
            },
            layout: 'noBorders'
        },
        {
            style: 'tableExample',
            margin : [0, 0, 0, 0],
            table : {
                widths: [117,'*'],
                body: [
                    [
                        {
                            text : 'Company/Institution :',
                            alignment : 'left',
                            style: 'tableLabel'
                        },
                        {
                            text : param['legal_name'],
                            alignment : 'left',
                            style: 'fontRegular'
                        }
                    ]
                ]
            },
            layout: 'noBorders'
        }
        /*{
            style: 'tableExample',
            margin : [0, 0, 0, 0],
            table : {
                widths: [100,'*'],
                body: s_query_body
            },
            layout: 'noBorders'
        }*/
    );


    //create footer
    var footer_arr = new Array();
    //http://localhost:4200/search-result/individual/NXcFankwjlQbA6HKiEfO
    footer_arr.push(
        {
            style: 'tableExample',
            margin : [0, 20, 0, 0],
            table : {
               
                widths: [60,'*',100],
                body: [
                    
                    [
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableInfo',
                           
                        },
                        {
                            text : 'This document is confidential and should only be shared with',
                            alignment : 'left',
                            style: 'tableLabelInfoFooter',
                            margin: [0, 15, 0, 0]
                        },
                        {
                            stack : [
                                {

                                    image: param['qr_code_base64img'],
                                    width : 50,
                                    height: 50,
                                    alignment : 'left',
                                    
                                }
                            ],
                            margin: [0, 5, 0, 0],
                            style: 'tableFooter',
                            rowSpan : 2
                        }
                    ],
                    [
                        {
                            text : '',
                            alignment : 'left',
                            style: 'tableInfo',
                           
                        },
                        {
                            text : 'authorized personnel involved in the search and investigation process.',
                            alignment : 'left',
                            style: 'tableLabelInfoFooter'
                        }
                    ]
                ]
            },
            layout: 'noBorders'
        }
    )
    //end create footer
    
    
    const document_definition = {
        content : template,
        
        background: [
            {
                image: param['report_bg'],
                absolutePosition: { x: 60, y: 259 },
            }
        ],
        footer : {
            columns: [
                {
                    stack : footer_arr
                }
            ]
        },
        pageSize: 'A4',
        images : {
            unawa_logo : {
                url : 'https://firebasestorage.googleapis.com/v0/b/cstf-v2.appspot.com/o/u_check_admin_staging%2Fmanifest_image%2FUNAWA%20Logo%20Horizontal%20-%20with%20TM%202%20(1).jpg?alt=media&token=030d50d2-30e4-44d6-94ba-d705a3b0d348'
            },
            check : {
                url : 'https://firebasestorage.googleapis.com/v0/b/cstf-v2.appspot.com/o/u_check_admin_staging%2Fmanifest_image%2F8666656_check_circle_icon%201%20(1).png?alt=media&token=eeff970b-753b-400e-8a71-8c46fea22869'
            }
        },
        pageMargins: [ 62,42,62,90 ],
        
        styles:{
            'with-color-white':{
                color:'#ffffff' // it will add a yellow background to all <STRONG> elements
            },
            'with-orange':{
                color:'#ffffff' // it will add a yellow background to all <STRONG> elements
            },
            tableExample: {
                margin: [0, 0, 0, 0]
            },
            tableHeader: {
                bold: true,
                fontSize: 14,
                color: '#000'
            },
            tableFooter: {
                fontSize: 8,
                color: '#ffffff'
            },
            tableFooterUnawa: {
                bold: true,
                fontSize: 10,
                color: '#ffffff'
            },
            tableInfo: {
                fontSize: 9,
                color: '#000'
            },
            tableInfoContact: {
                fontSize: 7,
                bold: true,
                color: '#000'
            },
            tableLabel: {
                fontSize: 12,
                color: '#000',
                bold : true
            },
            tableLabelInfo: {
                fontSize: 12,
                color: '#C2C2C1'
            },
            tableLabelInfoFooter: {
                fontSize: 9,
                color: '#C2C2C1'
            },
            fontRegular : {
                fontSize: 12,
            },
            defaultStyle: {
                color : '#F17821'
            }
        }
    };

    


    return document_definition;
}//end


function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        if(ctx !== null)
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
        };
        img.onerror = error => {
        reject(error);
        };
        img.src = url;
    });
}

async function getBase64(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      //corsHandler(resolve, reject, () => {
        var reader  = new FileReader();
        reader.addEventListener("load", function () {
            resolve(reader.result);
        }, false);
    
        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      //})
      
    })
  }