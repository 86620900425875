import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from '../services/dashboard-service';
import { Globals } from '../global/global-variable';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DatepickerOptions } from 'ng2-datepicker';
import { AssignTrialSubscriptionService } from '../services/assign-trial-subscription-service';
@Component({
  selector: 'app-assign-trial-subscription',
  templateUrl: './assign-trial-subscription.component.html',
  styleUrls: ['./assign-trial-subscription.component.scss']
})
export class AssignTrialSubscriptionComponent {
    
    form = this.fb.group({
        "user" : ['',Validators.required],
        "expiration" : ['',Validators.required],
    });
    show_no_data = true;

    select_data_list = new Array();

    date_option : DatepickerOptions = {
        //minYear: getYear(new Date()) - 30, // minimum available and selectable year
        //maxYear: getYear(new Date()) + 30, // maximum available and selectable year
        
        placeholder: 'Please pick a date', // placeholder in case date model is null | undefined, example: 'Please pick a date'
        format: 'MM/dd/yyyy', // date format to display in input
        formatTitle: 'LLLL yyyy',
        formatDays: 'EEEEE',
        //firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        //locale: locale, // date-fns locale
        position: 'bottom',
        inputClass: 'form-control', // custom input CSS class to be applied
        scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
        enableKeyboard: true
        
    };

    constructor(
        private fb : FormBuilder,
        private dashboardService : DashboardService,
        public global : Globals,
        private toastr : ToastrService,
        private assignTrialSubscriptionService : AssignTrialSubscriptionService
    ){
        
    }

    ngOnInit(){
        this.load_select();
    }
    save_data(){
        this.global.loading = true;
        const val = this.form.value;
        const final_date = new Date(val.expiration);
        var tmp_day        = final_date.getDate();
        var tmp_month      = final_date.getMonth();
        var tmp_year       = final_date.getFullYear();
        const final_date_formatted = (tmp_month+1) + "/" + tmp_day + "/" + tmp_year;
        
        const request_param = {
            "id" : val.user,
            "expiration" : final_date_formatted
        };
        this.assignTrialSubscriptionService.create_trial_subscription(request_param).subscribe((result) => {
            console.log(result);
            if(result["success"]){
                this.toastr.success("Operation successfully completed.","System Message");
            }else{
                this.toastr.error(result["data"]["error"]["message"],"System Message");
            }//end if
            this.global.loading = false;
        });
    }

  

    load_select(){
        this.global.loading = true;
        try {
            
            
            let filters = {};
            if(this.global.login_session["team_id"] !== undefined){
                filters["filterFieldArr"] = new Array();
                filters["filterOperatorArr"] = new Array();
                filters["filterValueArr"] = new Array();

                filters["filterFieldArr"].push('team_id');
                filters["filterOperatorArr"].push('==');
                filters["filterValueArr"].push(this.global.login_session["team_id"]);

                filters["filterFieldArr"].push('user_type');
                filters["filterOperatorArr"].push('==');
                filters["filterValueArr"].push('owner');

            }//end if

            const data_param = {
                "record_type" : environment.user_management,
                "pageSize" : 1000,
                ...filters
            }//end



            this.dashboardService.filterList(data_param).subscribe((response) => {

                console.log(response);
                if(response["success"]){
                    this.select_data_list                  = response["data"]["query_data"];
                }else{

                }//end if
                this.global.loading = false;
            })
        } catch (e : any) {
            this.toastr.error(e["message"],"System Message");
        }//end try
    }

    
}
