import { Component } from '@angular/core';
import { Globals } from '../global/global-variable';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from '../services/user-management-service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-manage-groups',
  templateUrl: './manage-groups.component.html',
  styleUrls: ['./manage-groups.component.scss']
})
export class ManageGroupsComponent {
    show_add_btn = false;
    show_edit_btn = false;
    show_delete_btn = false;
    filter_form = this.fb.group({
        filter_search : [''],
        page_size_placeholder : [1000000],
    });

    form = this.fb.group({
        id : [''],
        action : ['add'],
        name : ['',[Validators.required,Validators.pattern('[a-zA-Z ]*')]]
    });
    field_label = {
        "name" : "Name"
    }

    total_result = 1;
    total_result_user = 0;
    all_selected_values: string[] = [];
    //for pagination
    table_list = new Array();
    previous                    = false;
    lastVisibleField            = "id";
    lastVisibleVal              = "";
    prevPageVisibleVal          = new Array();
    pageNumber                  = 0;
    row_counter                 = 0;
    pagination_counter          = 0;
    pageSize                    = [1000000,20,50,100];

    show_total_search_result    = false;
    total_search_result         = 0;

    for_delete                  = {};
    session = this.global.login_session;
    constructor(
        private fb : FormBuilder,
        private toastr : ToastrService,
        public global : Globals,
        private userManagementService : UserManagementService
    ){
        //---------------------------------------check permission---------------------------------------
       let w_access     = false;
       const permission = this.global.login_session["permission"]["manage_groups"];
       for(const k in permission){
           if(permission[k] == 'view'){
               w_access = true;
           }//end if

           if(permission[k] == 'add'){
               this.show_add_btn = true;
           }//end if

           if(permission[k] == 'delete'){
               this.show_delete_btn = true;
           }//end if

           if(permission[k] == 'edit'){
               this.show_edit_btn = true;
           }//end if

           
           
       }//end for
       if(!w_access){
           window.location.replace('/main/dashboard');
       }//end if
       //---------------------------------------check permission---------------------------------------
    }

    ngOnInit(){
        this.refresh_list();
    }//end ngOnInit

    refresh_list(){
        const val               = this.filter_form.value;
        this.global.loading     = true;
        
        try{
            let filters = {};
            filters["filterFieldArr"]       = new Array();
            filters["filterOperatorArr"]    = new Array();
            filters["filterValueArr"]       = new Array();
            filters["orderByFieldArr"]      = new Array();
            filters["sortOrderArr"]         = new Array();

     
            
            filters["orderByFieldArr"].push("date_created");
            filters["orderByFieldArr"].push("id");

            filters["sortOrderArr"].push("desc");
            filters["sortOrderArr"].push("desc");

           
            
            const query_param = {
                "record_type" : environment.user_management,
                "pageNumber" : this.pageNumber,
                "lastVisibleField" : this.lastVisibleField,
                "lastVisibleVal" : this.lastVisibleVal,
                "previous" : this.previous,
                "pageSize" : val.page_size_placeholder,
                ...filters
            }//end

            console.log(query_param)
    
            //return this.settingService.filterList(query_param);
            this.userManagementService.filterList(query_param).subscribe(res => {
                console.log(res);
                if(res["success"]){
                    this.table_list                 = res["data"]["query_data"];
                    this.total_search_result        = res["data"]["total_result"];
                    this.total_result               = res["data"]["total_result"];
                    this.show_total_search_result   = true;
                    this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                    this.pagination_counter         = this.row_counter + (this.table_list.length - 1);
                }else{
                    console.log(res);
                    this.toastr.error(res["data"]["message"],"System Message");
                }//end if
                
                this.global.loading = false;
            });
        }catch(error : any){
            this.toastr.error(error["message"],"System Message");
        }//end try
        
        

        
    }//end

    search(){

    }//end search

    save_data(){

    }
    close(){

    }

    prev(){
        const val           = this.filter_form.value;;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.previous = true;
            this.lastVisibleVal         = this.prevPageVisibleVal[this.pageNumber];
            this.refresh_list();
        }//end if
        
        
        
    }

    next(){
        const val           = this.filter_form.value;;
        const total_pages   = Math.ceil((this.total_result / val.page_size_placeholder));
        
        if(total_pages > (this.pageNumber + 1)){
            
            this.pageNumber             += 1;
            this.previous               = false;
            this.lastVisibleVal         = this.table_list[this.table_list.length - 1]["id"];
           
            if(!this.prevPageVisibleVal.includes(this.table_list[0]["id"])){
                this.prevPageVisibleVal.push(this.table_list[0]["id"]);
            }//end if

            this.refresh_list();
            
            
        }//end if
    }

    onChange(value: string): void {
        if (this.all_selected_values.includes(value)) {
            this.all_selected_values = this.all_selected_values.filter(
            (item) => item !== value
            );
        } else {
            this.all_selected_values.push(value);
        }
        console.log(this.all_selected_values);
    }
}
