<div class="row align-items-center justify-content-center bg-light shadow" style="min-height: 0.76in;">
    <div class="col-auto">

    </div>
    <div class="col">
            <nav class="navbar">
                <h4>{{global.title}}</h4>
            </nav>
    </div>
</div>

