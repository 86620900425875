import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { CommonService } from "../library/pcl_lib";
import { Globals } from "../global/global-variable";
@Injectable({
    providedIn : "root"
})

export class CheckOutService {
    constructor(private router: Router,
        private http: HttpClient,
        private global : Globals,
        private lib : CommonService){        
    }

    public process_payment(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : environment.maya_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.maya_process_payment,q,requestOptions);
    }

    public process_subscription_zipph(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : environment.zipph_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.zipph_process_subscription,q,requestOptions);
    }

    public process_subscription_status_zipph(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : environment.zipph_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.zipph_process_subscription_status,q,requestOptions);
    }

    public process_payment_status(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : environment.maya_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.maya_process_payment_status,q,requestOptions);
    }

    public getSubscriptionList(): Observable<result_data>{
        return this.http.get(environment.get_subscription_list);
    }


    public save_data(function_param): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            //headers: new HttpHeaders().append('Authorization', 'Bearer '+localStorage.getItem("accessToken")),
            responseType: 'json',
            headers : headers
        }//end
        return  this.http.put(environment.save_data_staging,function_param,requestOptions);
    }//end

    
}

export interface result_data {}