import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, from, lastValueFrom, Observable, of } from "rxjs";
import { environment } from 'src/environments/environment';
import { CommonService } from "../library/pcl_lib";
import { Globals } from "../global/global-variable";
import { 
    RecaptchaVerifier,
    getAuth,
    signInWithPhoneNumber,
    multiFactor,
    PhoneAuthProvider,
    signInWithEmailAndPassword,
    getMultiFactorResolver,
    sendEmailVerification,
    PhoneMultiFactorGenerator } from "firebase/auth";
import { FirebaseApp } from "@angular/fire/app";

@Injectable({
    providedIn : "root"
})

export class MultifactorAuth {
    
    public recaptchaValidated = false;
    private auth = getAuth(this.fbApp);
    constructor(private router: Router,
        private fbApp : FirebaseApp,
        private http : HttpClient,
        private lib : CommonService){    
           
    }

    public setAuth(){
        this.auth = getAuth(this.fbApp);
    }

    public async sign_in(request_param){
        var retval = {
            success : false,
            data : null
        };
        const api = from(signInWithEmailAndPassword(this.auth, request_param["email"], request_param["password"])).pipe(
            catchError((error) => {
                
                const errorObj = {
                    "error" : JSON.parse(JSON.stringify(error))
                }
                // Return null or an appropriate value in case of an error
                return of(errorObj); // This can also be an Observable of your choice
            })
        );
        const result = await lastValueFrom(api);
        if(result["user"] !== undefined){
            retval["emailVerified"] = result['user']['emailVerified'];
            if(result['user']['emailVerified']){
                
                //------------------------------verified email------------------------------
                const multifactor_api =  await this.multiFactor_session(result['user'])
                if(multifactor_api["credential"] !== undefined){
                    const phoneInfoOptions = {
                        phoneNumber: request_param["phone"],
                        session: multifactor_api
                    };
                    console.log('phoneInfoOptions',phoneInfoOptions);
                    const verification = await this.send_verification_code(phoneInfoOptions);
                    if(verification["error"] === undefined){
                        retval["success"] = true;
                        retval["data"] = {
                            "message" : "Verification code sent!",
                            "user" : result['user'],
                            "verification_id" : verification,
                        }
                    }else{
                        retval["success"] = false;
                        retval["data"] = verification;
                    }//end if
                    
                }else{
                    retval["success"] = false;
                    retval["data"] = multifactor_api;
                }//end if
                
                //------------------------------verified email------------------------------
            }else{
                //------------------------------unverified email------------------------------
                const email_verification = await this.send_email_verification(result["user"]);

                if(email_verification === undefined){
                    retval["success"] = true;
                    retval["data"] = {
                        "message" : "Verification email sent!"
                    };
                }else{
                    if(email_verification["error"] === undefined){
                        retval["success"] = true;
                        retval["data"] = {
                            "message" : "Verification email sent!"
                        };
                    }else{
                        retval["success"] = false;
                        retval["data"] = email_verification;
                    }//end if
                }//end if
                
                
                //------------------------------unverified email------------------------------
            }//end if
            
        }else{

            if(result["error"]["code"] == 'auth/multi-factor-auth-required'){
                const resolver = getMultiFactorResolver(this.auth, result["error"]);
                if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                    const phoneInfoOptions = {
                        multiFactorHint: resolver.hints[0],
                        session: resolver.session,
                    };
                    const verification = await this.send_verification_code(phoneInfoOptions);
                    if(verification["error"] === undefined){
                        retval["success"] = true;
                        retval["data"] = {
                            "error" : result["error"],
                            "message" : "Verification code sent!",
                            "user" : result['user'],
                            "verification_id" : verification,
                        }
                    }else{
                        retval["success"] = false;
                        retval["data"] = verification;
                    }//end if
                }else{
                    retval["success"] = false;
                    retval["data"] = {
                        "message" : "Something went wrong!"
                    }
                }//end if
            }else{
                retval["success"] = false;
                retval["data"] = result;
            }//end

            
        }//end if


        return retval;


        /*await signInWithEmailAndPassword(auth, 'plangaman+2@unawa.asia', 'Admin1234').then((userRecord) => {
			
			
            retval["data"]["user"] = userRecord.user;
			multiFactor(userRecord.user).getSession().then(async function (multiFactorSession) {
				const phoneInfoOptions = {
					phoneNumber: '+639517624489',
					session: multiFactorSession
				};

				const phoneAuthProvider = new PhoneAuthProvider(auth);
			
			    await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
				.then((verificationId) => {
					console.log('verificationId',verificationId);
                    retval["data"]['verificationId'] = verificationId;

					
					
					// verificationId will be needed to complete enrollment.
				}).catch((e) => {
					recaptchaVerifier.clear();
					
                    retval["data"] = e;
				});
			}).catch((e) => {
                retval["data"] = e;
            });
		}).catch((e) => {
            retval["data"] = e;
        });

        return retval;
        */
    }

    async send_email_verification(user){
        const api = from(sendEmailVerification(user)).pipe(
            catchError((error) => {
                const errorObj = {
                    "error" : JSON.parse(JSON.stringify(error))
                }
                // Return null or an appropriate value in case of an error
                return of(errorObj); // This can also be an Observable of your choice
            })
        );

        const res = await lastValueFrom(api);
        return res;
    }

    async send_verification_code(phoneInfoOptions){
        const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', {
            "size" : "invisible"
        }, this.auth);
        const phoneAuthProvider = new PhoneAuthProvider(this.auth);
        console.log('send_verification_code',phoneInfoOptions);
        const api = from(phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)).pipe(
            catchError((error) => {
                const errorObj = {
                    "error" : JSON.parse(JSON.stringify(error))
                }
                // Return null or an appropriate value in case of an error
            return of(errorObj); // This can also be an Observable of your choice
            })
        );
        const res = await lastValueFrom(api);

        //insert verification_id to firebase
        const login_session = JSON.parse(localStorage.getItem("login_session"));
        const EXPIRATION_DURATION = 5 * 60 * 1000;
        const expiration_time = Date.now() + EXPIRATION_DURATION;
        const request_param = {
            "id" : login_session["id"],
            "record_type"   : environment.user_management,
            "action" : "update",
            "MFA_verification_id" : res,
            "MFA_verification_id_exp" : expiration_time
        };

        console.log('request_param',request_param);

        const save_response = await lastValueFrom(this.save_verification_id(request_param));
        console.log('save_response',save_response);
        //insert verification_id to firebase


        return res;
    }

    async multiFactor_session(user){
        const multifactor_api = from(multiFactor(user).getSession()).pipe(
            catchError((error) => {
                const errorObj = {
                    "error" : JSON.parse(JSON.stringify(error))
                }
                // Return null or an appropriate value in case of an error
            return of(errorObj); // This can also be an Observable of your choice
            })
        );

        const res = await lastValueFrom(multifactor_api);
        return res;
    }



    public save_verification_id(q): Observable<result_data>{
        let response = {
            success : false,
            data : {}
        }//end

        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+environment.web_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.create_user,q,requestOptions);

    }


    async enroll_multifactor(verification_id,verification_code,user){
        const cred = PhoneAuthProvider.credential(verification_id, verification_code);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        multiFactor(user).enroll(multiFactorAssertion, "Initial 2FA");
    }//end

    async authenticate_multifactor(verification_id,verification_code,error){
        const cred = PhoneAuthProvider.credential(verification_id, verification_code);
		const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        const resolver = getMultiFactorResolver(this.auth, error);
        const api = from(resolver.resolveSignIn(multiFactorAssertion)).pipe(
            catchError((error) => {
                const errorObj = {
                    "error" : JSON.parse(JSON.stringify(error))
                }
                // Return null or an appropriate value in case of an error
                return of(errorObj); // This can also be an Observable of your choice
            })
        );

        const res = await lastValueFrom(api);
        return res;
    }

    generate_otp(q) : Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+environment.web_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.generate_otp,q,requestOptions);
    }

    verify_otp(q) : Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+environment.web_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.verify_otp,q,requestOptions);
    }


    async signin(param){
        const auth = getAuth(this.fbApp);
        const recaptchaVerifier2 = new RecaptchaVerifier('recaptcha-container', undefined, auth);
        await signInWithEmailAndPassword(auth,param.email,param.password)
            .then((userCredential) => {
                console.log('userCredential',userCredential)
                multiFactor(userCredential.user).getSession().then(function (multiFactorSession) {
                    const phoneInfoOptions = {
                        phoneNumber: '+639171551303',
                        session: multiFactorSession
                    };
                    const phoneAuthProvider = new PhoneAuthProvider(auth);
                    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier2)
                    .then(function (verificationId) {
                        console.log('verificationId',verificationId);
                        // verificationId will be needed to complete enrollment.
                    });
                });
                
                //return true;
            }).catch((error) => {
                console.log('errorsss',JSON.stringify(error))
                if (error.code == 'auth/multi-factor-auth-required') {
                   var resolver = getMultiFactorResolver(auth, error);
                   const phoneInfoOptions = {
                        multiFactorHint: resolver.hints[0],
                        session: resolver.session
                    };

                    console.log('phoneInfoOptions',phoneInfoOptions)

                    const phoneAuthProvider = new PhoneAuthProvider(auth);
                    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier2)
                    .then(function (verificationId) {
                        console.log('verificationId',verificationId)
                        // verificationId will be needed for sign-in completion.
                    });
                }
           
            });
    }


    
   
}

export interface result_data {}

