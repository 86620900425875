import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Globals } from '../global/global-variable';
import { ToastrService } from 'ngx-toastr';
import { GeneratedManifestService } from '../services/generated-manifest-service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-generated-manifest',
  templateUrl: './generated-manifest.component.html',
  styleUrls: ['./generated-manifest.component.scss']
})
export class GeneratedManifestComponent {
    public table_list = new Array();
    show_no_data = true;
    
    //------------------for pagination------------------
    previous                    = false;
    lastVisibleField            = "id";
    lastVisibleVal              = "";
    prevPageVisibleVal          = new Array();
    pageNumber                  = 0;
    row_counter                 = 0;
    pagination_counter          = 0;
    pageSize                    = [10,20,50,100];
    show_total_search_result    = false;
    total_search_result         = 0;
    total_result                = 0;
    //------------------for pagination------------------

    filter_form = this.fb.group({
        filter_search : [''],
        page_size_placeholder : [10],
    });

    constructor(
        private fb : FormBuilder,
        public global : Globals,
        private generatedManifestService : GeneratedManifestService,
        private toastr : ToastrService,
        private datePipe : DatePipe
    ){

    }//end constructor

    ngOnInit(){

        
        //check permission
        let w_access     = false;
        //const permission = this.global.login_session["permission"]["generated_manifest"];
        const permission = this.global.login_session["permission"]["generated_manifest"];
        
        for(const k in permission){
            if(permission[k] == 'view'){
                w_access = true;
            }//end if

        }//end for

        if(this.global.login_session["team_id"] === undefined && this.global.login_session["user_type"] !== "super_admin"){
            w_access = false;
        }//
        
        if(!w_access){
           window.location.replace('/main/dashboard');
        }//end if
        //end check permission

        this.refresh_list();
    }

    refresh_list(){
        const val               = this.filter_form.value;
        this.global.loading     = true;
        try{
            let filters = {};
            filters["filterFieldArr"]       = new Array();
            filters["filterOperatorArr"]    = new Array();
            filters["filterValueArr"]       = new Array();
            filters["orderByFieldArr"]      = new Array();
            filters["sortOrderArr"]         = new Array();


            filters["filterFieldArr"].push("created_by");
            filters["filterOperatorArr"].push("==");
            filters["filterValueArr"].push(this.global.login_session["id"]);

            

            if(val.filter_search !== ""){
                filters["filterFieldArr"].push("search_full_name");
                filters["filterOperatorArr"].push("array-contains");
                filters["filterValueArr"].push(val.filter_search.toLowerCase());
            }//end if
            
            filters["orderByFieldArr"].push("date_created");
            filters["orderByFieldArr"].push("id");

            filters["sortOrderArr"].push("desc");
            filters["sortOrderArr"].push("desc");

           
            
            const query_param = {
                "record_type" : environment.generated_manifest,
                "pageNumber" : this.pageNumber,
                "lastVisibleField" : this.lastVisibleField,
                "lastVisibleVal" : this.lastVisibleVal,
                "previous" : this.previous,
                "pageSize" : val.page_size_placeholder,
                ...filters
            }//end
    
            //return this.settingService.filterList(query_param);
            this.generatedManifestService.filterList(query_param).subscribe(res => {
                console.log(res);
                if(res["success"]){
                    this.table_list                 = res["data"]["query_data"];
                    this.total_search_result        = res["data"]["total_result"];
                    this.total_result               = res["data"]["total_result"];
                    this.show_total_search_result   = true;
                    this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                    this.pagination_counter         = this.row_counter + (this.table_list.length - 1);

                    if(this.total_result > 0){
                        this.show_no_data = false;
                    }//end if

                    //convert date
                    for(var k in this.table_list){
                        const es = this.table_list[k];
                        //if(es["date_created"] !== undefined){
                        var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                        this.table_list[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                        //}//end if

                        if(es["search_category"] == undefined){
                            this.table_list[k]["view_link"] = "/search-result/report/"+es['type']+'/'+es["id"];
                        }else if(es["search_category"] == 'wikidata'){
                            this.table_list[k]["view_link"] = "/search-result/report-wiki/"+es['type']+'/'+es["id"]+'/'+es["page"];
                        }else if(es["search_category"] == 'wikidata-single'){
                            this.table_list[k]["view_link"] = "/search-result/report-wiki-single/"+es['type']+'/'+es["id"];
                        }else if(es["search_category"] == 'adverse'){
                            this.table_list[k]["view_link"] = "/search-result/report-adverse/"+es['type']+'/'+es["id"];
                        }else if(es["search_category"] == 'adverse-single'){
                            this.table_list[k]["view_link"] = "/search-result/report-adverse-single/"+es['type']+'/'+es["id"];
                        }else if(es["search_category"] == 'sanction-single'){
                            this.table_list[k]["view_link"] = "/search-result/report-single/"+es['type']+'/'+es["id"];
                        }//end if//end if

                       

                    }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                    //convert date
                }else{
                    console.log(res);
                    this.toastr.error(res["data"]["message"],"System Message");
                }//end if
                
                this.global.loading = false;
            });
        }catch(error : any){
            this.toastr.error(error["message"],"System Message");
        }//end try
    }

    search(){
        
        this.previous                    = false;
        this.lastVisibleField            = "id";
        this.lastVisibleVal              = "";
        this. prevPageVisibleVal          = new Array();
        this.pageNumber                  = 0;
        this.refresh_list()
    }

    prev(){
        const val           = this.filter_form.value;;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.previous = true;
            this.lastVisibleVal         = this.prevPageVisibleVal[this.pageNumber];
            this.refresh_list();
        }//end if
    }

    next(){
        const val           = this.filter_form.value;;
        const total_pages   = Math.ceil((this.total_result / val.page_size_placeholder));

        if(total_pages > (this.pageNumber + 1)){
            this.pageNumber             += 1;
            this.previous               = false;
            this.lastVisibleVal         = this.table_list[this.table_list.length - 1]["id"];

            if(!this.prevPageVisibleVal.includes(this.table_list[0]["id"])){
                this.prevPageVisibleVal.push(this.table_list[0]["id"]);
            }//end if
            this.refresh_list();
        }//end if
    }

}
